import { Stack } from "@mui/material"
import { useParams } from "react-router-dom";
import BreadcrumbsComponent from "../../components/breadcrumbs";
import DetailContainer from "../../containers/detail";
import { module as profiles } from '../../module/profiles/redux'
import { module as api } from '../../module/profiles/api'
import { module as views } from '../../module/profiles/views/detail'
import { module as reset } from '../../module/profiles/views/reset'
import { module as payment } from '../../module/profiles/views/payment'
import DetailComponent from "../../components/detail";
import ResetContainer from "../../containers/password/reset";
import Reset from "../../components/password";
import ChangeContainer from "../../containers/payment/change";
import Change from "../../components/payment/change";

const links = [{
    to: '/profiles',
    label: 'Utenti'
}]

const DetailPage = () => {

    const { id } = useParams();

    return (
        <Stack sx={{ width: '100%', height: '100%' }}>
            <BreadcrumbsComponent links={[...links, { to: `/profiles/${id}`, label: id }, { to: id, label: 'Dettaglio' }]} />

            <DetailContainer selector={profiles.selectors.detail.profile} slice={profiles.slices.detail.profile} api={api.profile} params={{ id }}>
                <DetailComponent fields={views.profile.fields} render={views.profile.formatValue} />
            </DetailContainer>

            <ResetContainer api={api.profile} selector={profiles.selectors.detail.profile}>
                <Reset fields={reset.fields} schema={reset.schema} />
            </ResetContainer>

            <ChangeContainer selector={profiles.selectors.detail.profile}>
                <Change fields={payment.fields} schema={payment.schema} />
            </ChangeContainer>

        </Stack>
    )
}

export default DetailPage