import React, { Fragment } from 'react';
import MuiTypography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Avatar, Box, Stack } from '@mui/material';

const Section = ({
	label,
	icon,
	children
}) => {

	return (
		<Fragment>
			<Stack spacing={1} direction='row' alignItems={'center'} padding={1} color='secondary.main' bgcolor={'background.paper'} borderBottom={1} borderColor={'border.main'}>
				<Avatar sx={{ bgcolor: 'secondary.main' }}>
					{icon}
				</Avatar>
				
				<MuiTypography variant={'sectionTitle'}>
					{label.toUpperCase()}
				</MuiTypography>
			</Stack>
			<Box p={1} bgcolor={'background.paper'} mb={2}>				
				{children}				
			</Box>
		</Fragment>
	);
};

Section.propTypes = {
	label: PropTypes.string,
};

export default Section;
