import TableCell, { CellType, CellVariant } from '../../../../components/table/cell';

export const ListConf = {	
	rowsPerPageOptions: [ 50, 100, 150, 200],
	columns: [		
		{
			id: 'name',
			key: 'name',
			sort_key: 'name',
			label: 'Nome',
		},
		{
			id: 'newspaper.title',
			key: 'newspaper.title',
			sort_key: 'newspaper.title',
			label: 'Testata',
		},
		{
			id: 'created_at',
			key: 'created_at',
			sort_key: 'created_at',
			label: 'Data creazione',
		},
		{
			id: 'product_special',
			key: 'product_special',
			sort_key: 'product_special',
			label: 'Tipo',
			sortable: false,
		}
	],
	render: {
		formatHead: (_, value) => {
			return (
				<TableCell variant={CellVariant.head} value={value} />
			);
		},
		formatValue: (key, value) => {
			switch (key) {
				case 'name':
					return (
						<TableCell type={CellType.product} value={value} />
					)
				case 'created_at':
					return (
						<TableCell type={CellType.date} value={value} />
					)
				case 'product_special':
					const status = {label: value? 'Speciale' : 'Standard', color: value? 'error' : 'primary'}
					return (
						<TableCell type={CellType.status} value={status} />
					)					
				default:
					return (
						<TableCell type={CellType.default} value={value} />
					);
			}
		},
	},
	actions:{
		download: false
	}
};

export const module = {
    ...ListConf
}
