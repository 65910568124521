import { Box } from "@mui/material"
import { Fragment } from "react";
import ClipboardComponent from "../../components/clipboard";
import BreadcrumbsComponent from "../../components/breadcrumbs";
import CreateContainer from "../../containers/create";
import { module as api } from '../../module/coupons/api'
import { module as create } from '../../module/coupons/views/create'
import Create from "../../components/add";


const CouponsCode = ({
    data
}) => {

    return (
        data && <ClipboardComponent text={data.redeem_codes.join("\n")} />
    )
}

const CouponsCreate = ({
    data,
    ...props
}) => {
    return (
        !data && <Create {...props} fields={create.fields} schema={create.schema} />
    )
}

const links = [{
    to: '/coupons',
    label: 'Coupons'
}]

const AddPage = () => {

    return (
        <Fragment>
            <BreadcrumbsComponent links={[...links, { to: '/', label: 'Creazione' }]} />
            <Box sx={{ width: '100%', padding: 1, borderRadius: 1, backgroundColor: 'background.paper' }}>
                <CreateContainer api={api}>
                    <CouponsCreate />
                    <CouponsCode />
                </CreateContainer>
            </Box>
        </Fragment>
    )
}

export default AddPage