import { createSliceData } from '../../../core/data/redux';
import { createSelectorDetail, createSliceDetail } from '../../../core/detail/redux';
import { createSliceFilter } from '../../../core/filter/redux';
import { createSliceList } from '../../../core/list/redux';
import { createSliceSearch } from '../../../core/search/redux';
import { FilterConf } from '../conf/filter';
import { createSelector } from "@reduxjs/toolkit";
import { QueryConf } from "../conf/query";
import { createSelectorList } from "../../../core/list/redux";
import { createSelectorSearch } from "../../../core/search/redux";
import { createSelectorFilter } from "../../../core/filter/redux";
import { createSelectorData } from "../../../core/data/redux";
import { createSelectorQuery } from "../../../core/query";
import { PricesConf, ProductConf } from '../conf/detail';

const tag = 'products'

export const createSelectors = (tag) => ({    
    search: createSelectorSearch(`${tag}.all`),    
    filter: createSelectorFilter(`${tag}.all`),  
    detail: {
        product: createSelectorDetail(`${tag}.detail.product`, ProductConf.set),
        prices: createSelector([
            createSelectorDetail(`${tag}.detail.prices`),
            createSelectorDetail(`${tag}.detail.product`)
        ],  (prices, product) => PricesConf.set(prices, product)),        
    },
    all: createSelector([
        createSelectorList(`${tag}.all`),
        createSelectorData(`${tag}.all`),
        createSelectorQuery(`${tag}.all`, QueryConf.get),
    ],(list, data, query) => ({ list, data, query }))
})

const createSlices = (tag) => ({
    detail: {
        product: createSliceDetail(`${tag}/detail/product`),
        prices: createSliceDetail(`${tag}/detail/prices`),
    },
    all: {
        data: createSliceData(`${tag}/all/data`),
        list: createSliceList(`${tag}/all/list`),
        search: createSliceSearch(`${tag}/all/search`),
        filter: createSliceFilter(`${tag}/all/filter`, FilterConf.initialState),
    }
})

export const module = {
    tag,
    selectors: createSelectors(tag),
    slices: createSlices(tag)
}