import * as yup from 'yup';
import { SubscriptionCancellation } from '../../../../utility/Datasource.utility';

export const DeleteConf = {
    schema: yup.object({
        cancel_type: yup.object().nullable().required('Campo obbligatorio').typeError('Campo obbligatorio'),
    }),
    defaultValues: {
        cancel_type: null,
        profile_auth0: null,
        subscription_id: null,
        cancellation_details_comment: null
    },
    fields: [{
        id: 'cancel_type',
        name: 'cancel_type',
        type: 'autocomplete',
        label: 'Tipo di disdetta',
        required: true,
        options: SubscriptionCancellation,
        xs: 12,
    }, {
        id: 'cancellation_details_comment',
        name: 'cancellation_details_comment',
        type: 'text',
        label: 'Note',
        multiline: true,
        rows: 4,
        required: false,
        xs: 12,
    }],
};

export const module = {
    ...DeleteConf
}