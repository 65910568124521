import React, { useCallback } from 'react';
import MuiTableBody from '@mui/material/TableBody';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableRow from '@mui/material/TableRow';
import _get from 'lodash/get';
import { v4 as uuidv4 } from 'uuid';

const TableBody = ({
	rows = [],
	columns,
	selected = [],
	onClick = () => { },
	onSelected = () => { },
	formatValue = () => { },
}) => {

	const isSelected = useCallback((id) => selected.indexOf(id) !== -1, [selected]);

	return (
		<MuiTableBody>
			{rows.map((row) => {
				const isItemSelected = isSelected(row.id);
				return (
					<MuiTableRow hover tabIndex={-1} key={uuidv4()} selected={isItemSelected}>
						{columns.map(({ key, align='inherit', id, padding = 'normal', size = 'medium', backgroundColor = 'background.main' }) => (
							<MuiTableCell
								padding={padding}
								key={uuidv4()}
								align={align}
								size = {size}								
								sx={{ cursor: 'pointer', backgroundColor }}
								onClick={() => key === 'checkbox' ? onSelected(row) : onClick(row)}>
								{formatValue(key, _get(row, id), isItemSelected)}
							</MuiTableCell>
						))}
					</MuiTableRow>
				);
			})}
		</MuiTableBody>
	);
};

export default TableBody;
