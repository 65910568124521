import { Button } from "@mui/material"
import { Link } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';

const AddButton = ({
    text
}) => {

    return (
        <Link to='add'>
            <Button
                variant='contained'
                color="primary"
                startIcon={<AddIcon />}>
                {text}
            </Button>
        </Link>
    )
}

export default AddButton