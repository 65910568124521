import Button from "../../form/components/button"
import FilterListIcon from '@mui/icons-material/FilterList';

const SubmitButton = () => {

    return (
        <Button type="submit" variant="contained" size='small' startIcon={<FilterListIcon />}>
            Ricerca
        </Button>
    )
}

export default SubmitButton