import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import _slice from 'lodash/slice'

const List = ({
    data,
}) => {

    return (
        data?.list && 
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontSize: 16, fontWeight: 'bold' }}>Testata</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: 'text.secondary' }} align="left">Edizione</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: 'text.secondary' }}>Copia del</TableCell>
                        <TableCell sx={{ fontWeight: 'bold', color: 'text.secondary' }} align="right">Totale</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {_slice(data.list, 0, 10).map((row) =>
                        <TableRow key={uuidv4()}>
                            <TableCell component="th" scope="row" sx={{ fontWeight: 'bold', color: 'text.secondary' }}>{row.newspaper.label}</TableCell>
                            <TableCell component="th" scope="row" sx={{ fontWeight: 'bold', color: 'text.secondary' }} align="left">{row.edition}</TableCell>
                            <TableCell component="th" scope="row" sx={{ fontWeight: 'bold', color: 'text.secondary' }}>{row.date}</TableCell>
                            <TableCell sx={{ fontSize: 16, fontWeight: 'bold' }} align="right">{row.count}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default List