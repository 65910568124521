import React, { useEffect, useState, Fragment } from 'react';
import Error from '../error';
import { useController, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { fetchAPI } from '../../../../api/request';
import _ from 'lodash';
import { grey } from '@mui/material/colors';
import { v4 as uuidv4 } from 'uuid';
import { Box as MUIBox, Stack as MUIStack, Autocomplete as MUIAutocomplete, TextField as MUITextField, CircularProgress as MUICircularProgress } from '@mui/material';
import { module as auth } from '../../../../module/user/redux';

const AsyncAutocomplete = ({ name, required, url, api, label, ...props }) => {
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const { jwt } = useSelector(auth.selectors.user)
	const [loading, setLoading] = useState(false);

	const { control, formState: { errors } } = useFormContext();

	const { field: { ref, onChange, value, ...inputProps } } = useController({ name: name, control: control, rules: { required } });

	useEffect(() => {
		setLoading(true);
		//onChange(null) 
		fetchAPI(api, {}, {}, {}, jwt)
			.then((response) => {
				setLoading(false);
				setOptions(response);
			})
			.catch(() => {
				setLoading(false);
				setOptions([]);
			});
	}, [jwt, api]);

	useEffect(() => {

		if (value && options.length > 0) {
			if (_.find(options, (item) => item.id === value.id) === undefined) {
				onChange(null)
			}
		}

	}, [value, options, onChange])

	return (
		<MUIStack spacing={1}>
			<MUIAutocomplete
				{...inputProps}
				ref={ref}
				open={open}
				value={value || null}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				options={options}
				getOptionLabel={(option) => option.name}
				isOptionEqualToValue={(option, value) =>
					option.name === value.name && option.id === value.id
				}
				onChange={(e, data) => onChange(data)}
				filterOptions={(options, state) => {
					// Filter out any options that have the same name as the user's input value
					const inputValue = state.inputValue.toLowerCase();
					return options.filter((option) =>
						_.includes(option.name.toLowerCase(), inputValue.toLowerCase())
					);
				}}
				renderOption={(_, option) => (
					<MUIBox
						key={uuidv4()}
						onClick={() => {
							setOpen(false)
							onChange(option);
						}}
						padding={0.5}
						paddingLeft={1}
						sx={{
							'&:hover': {
								backgroundColor: grey[200],
							},
						}}
					>
						{option?.name}
					</MUIBox>
				)}
				loading={loading}
				renderInput={(params) => (
					<MUITextField
						{...params}
						label={label}
						required={required}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<Fragment>
									{loading ? (
										<MUICircularProgress color='inherit' size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</Fragment>
							),
						}}
						error={!!errors[name]?.message}
						size="small"
					/>
				)}
				{...props}
			/>
			<Error error={errors[name]?.message} />
		</MUIStack>
	);
};

AsyncAutocomplete.propTypes = {
	name: PropTypes.string,
	required: PropTypes.bool,
	label: PropTypes.string,
};

export default AsyncAutocomplete;
