import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useRole } from "../../hooks/role/useRole";
import { Fragment, useCallback, useEffect } from "react";
import { module as auth } from '../../module/user/redux'
import { module as saga } from '../../module/user/saga'
import { module as api } from '../../module/user/api'
import { module as view } from '../../module/user/view'
import Loading from "../../components/common/loading";
import Alert from '../../components/common/alert';
import { Form } from "../../components/form";
import { Box, Button } from "@mui/material";

const LoginContainer = () => {

    const { selectors } = auth
    const { action } = saga
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { root } = useRole()
    const { loading, error, user } = useSelector(selectors.user);

    const onSubmit = useCallback((values) => {
        dispatch(action({
            api: api.login,
            body: { ...values }
        }));
    }, [dispatch]);

    useEffect(() => {
        if (user && root) {
            navigate(root, { replace: true });
        }
    }, [user, root, navigate]);


    return (
        <Fragment>
            {loading && <Loading />}
            {error && <Alert severity='error'>{error}</Alert>}
            <Form fields={view.fields} schema={view.schema} defaultValues={view.defaultValues} onSubmit={onSubmit}>
                <Box display={'flex'} justifyContent='flex-end'>
                    <Button fullWidth type="submit" variant="contained" sx={{ mt: 1, mb: 1 }}>
                        Login
                    </Button>
                </Box>
            </Form>
        </Fragment>

    )

}

export default LoginContainer