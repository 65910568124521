import { takeLatest, all } from 'redux-saga/effects'

export function* create(action, saga, params) {
    yield takeLatest(action, saga, params);
}

export function* createSaga(sagas) {

    const list = sagas.map(({ action, saga, tag }) => {        
        return create(action, saga, tag);
    });

    yield all(list);
}