import { createAction } from "@reduxjs/toolkit";
import { loginSaga } from "../../../core/user/saga";

const tag = 'user'
const action = createAction(`${tag}/fetch`)

const createSaga = (tag) => [{
    action,
    tag,
    saga: loginSaga
}]

export const module = {
    tag,
    action,
    saga: createSaga(tag)
}