import { Stack, Divider, Grid } from "@mui/material"
import dayjs from 'dayjs';
import Text from "../../../../components/detail/text";
import Row from "../../../../components/detail/row";
import { useMemo } from "react";
import { v4 as uuidv4 } from 'uuid';

const ProductCard = ({
    product
}) => {

    const info = useMemo(() => [
        ['Testata', product.newspaper?.title],
        ['Data di Creazione', dayjs(product.created_at).format('DD/MM/YYYY')],
        ['Stripe ID', product.id_stripe],
    ], [product])

    return (
        <Stack spacing={1} divider={<Divider />}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>                
                <Text title={product.name} />
            </Stack>

            <Grid container>                
                {info.map((item) =>
                    <Row key={uuidv4()} item={
                        <Text enabled={true} variant="body1" title={item[0]} value={item[1]} />
                    } />)}
            </Grid>

        </Stack>
    )
}

export default ProductCard