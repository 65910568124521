export const SearchConf = {
    query: {
        keys: [{
            key: 'code',
            operator: 'contains'
        }, {
            key: 'profile.email',
            operator: 'contains'
        },{
            key: 'customer_email',
            operator: 'contains'
        }]
    },   
}