import { useSelector } from 'react-redux';
import { isValidElement, Children, cloneElement } from "react";
import { useSearch } from '../../hooks/search/useSearch';

const SearchContainer = ({
    selector,    
    slice,
    children
}) => {

    const { actions } = slice
    const { value } = useSelector(selector);

    const { onKeyDown, onChange, onClick, text } = useSearch(actions.set, value);

    const childWithProps = Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child, { onKeyDown,  onChange , onClick , value: text });
        }
        return child;
    });

    return childWithProps
};

export default SearchContainer;