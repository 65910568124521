import { createSelector } from "@reduxjs/toolkit";
import { createSelectorList } from "../../../core/list/redux";
import { createSelectorSearch } from "../../../core/search/redux";
import { createSelectorData } from "../../../core/data/redux";
import { createSelectorQuery } from "../../../core/query";
import { createSelectorDetail } from "../../../core/detail/redux";
import { createSliceData } from '../../../core/data/redux';
import { createSliceDetail } from '../../../core/detail/redux';
import { createSliceList } from '../../../core/list/redux';
import { createSliceSearch } from '../../../core/search/redux';
import { QueryConf } from "../conf/query";
import { ProfileConf, SubscriptionsConf, PurchasesConf } from "../conf/detail";

const tag = 'profiles'

export const createSelectors = (tag) => ({    
    search: createSelectorSearch(`${tag}.all`),
    detail: {
        profile: createSelectorDetail(`${tag}.detail.profile`, ProfileConf.set),
        subscriptions: createSelectorDetail(`${tag}.detail.subscriptions`, SubscriptionsConf.set),
        purchases: createSelectorDetail(`${tag}.detail.purchases`, PurchasesConf.set)
    },
    all: createSelector([
        createSelectorList(`${tag}.all`),
        createSelectorData(`${tag}.all`),
        createSelectorQuery(`${tag}.all`, QueryConf.get),
    ],(list, data, query) => ({ list, data, query }))
})


const createSlices = (tag) => ({
	detail: {
		profile: createSliceDetail(`${tag}/detail/profile`),
		subscriptions: createSliceDetail(`${tag}/detail/subscriptions`),
		purchases: createSliceDetail(`${tag}/detail/purchases`)
	},
	all: {
		data: createSliceData(`${tag}/all/data`),
		list: createSliceList(`${tag}/all/list`),
		search: createSliceSearch(`${tag}/all/search`),		
	}
})

export const module = {
    tag,
    selectors: createSelectors(tag),
    slices: createSlices(tag)
}