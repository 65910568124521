import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import Loading from '../../../../../components/common/loading';

const List = ({
    loading,
    data,
}) => {

    return (
        data?.list &&
        <Stack spacing={2}>
            {loading && <Loading type='progress' />}
            {data.list.map((item) =>
                <TableContainer key={uuidv4()} >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontSize: 16, fontWeight: 'bold', width: 200, backgroundColor: 'primary.main', color: 'white' }}>{item.name}</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }} align="right">Settimanale</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }} align="right">Mensile</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }} align="right">Trimestrale</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }} align="right">Semestrale</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }} align="right">Annuale</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'primary.main', color: 'white' }} align="right">Totale</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {item?.products.map((row) =>
                                <TableRow key={uuidv4()}>
                                    <TableCell component="th" scope="row" sx={{ fontWeight: 'bold', color: 'text.secondary' }}>{row.name}</TableCell>
                                    <TableCell align="right">{row['1w'] || '-'}</TableCell>
                                    <TableCell align="right">{row['1m'] || '-'}</TableCell>
                                    <TableCell align="right">{row['3m'] || '-'}</TableCell>
                                    <TableCell align="right">{row['6m'] || '-'}</TableCell>
                                    <TableCell align="right">{row['12m'] || '-'}</TableCell>
                                    <TableCell align="right" sx={{ fontWeight: 'bold' }} >{row['total']}</TableCell>
                                </TableRow>
                            )}
                            <TableRow>
                                <TableCell component="th" scope="row" sx={{ color: 'text.secondary', backgroundColor: 'background.default' }}>Totale</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'background.default' }} align="right">{item?.totals?.['1w'] || '-'}</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'background.default' }} align="right">{item?.totals?.['1m'] || '-'}</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'background.default' }} align="right">{item?.totals?.['3m'] || '-'}</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'background.default' }} align="right">{item?.totals?.['6m'] || '-'}</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'background.default' }} align="right">{item?.totals?.['12m'] || '-'}</TableCell>
                                <TableCell sx={{ fontWeight: 'bold', backgroundColor: 'background.default' }} align="right">{item?.total || '-'}</TableCell>                                
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Stack>
    )
}

export default List