import { useEffect, isValidElement, Children, cloneElement } from "react";
import { useDelete } from "../../hooks/delete/useDelete"
import { useNavigate } from "react-router-dom";

const DeleteContainer = ({
    id,
    api,
    to,
    children
}) => {

    const navigate = useNavigate()
    const { loading, success, deleteData } = useDelete(api)

    const onDelete = () => {
        deleteData({ id }, null)
    }

    useEffect(() => {
        if (success) {
            navigate(to)
        }
    }, [success, navigate, to])

    const childWithProps = Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child, { loading,  onDelete, disabled: loading });
        }
        return child;
    });

    return childWithProps
}

export default DeleteContainer