import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Router from './router/Router';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { ThemeProvider } from '@mui/material';
import { theme } from './themes/main.theme';
import { APIProvider } from './api/manager'
import 'dayjs/locale/it'

function App() {
	return (
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<APIProvider>
						<Router />
					</APIProvider>
				</BrowserRouter>
			</ThemeProvider>
		</Provider>
	);
}

export default App;