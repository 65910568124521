import React from "react";
import { LinearProgress, Backdrop, CircularProgress } from "@mui/material";

const Loading = ({
    type = 'backdrop'
}) => {

    if(type === 'progress'){
        return <LinearProgress color="inherit" />
    }


    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default Loading