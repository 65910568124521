import _get from 'lodash/get'
import { createSlice, createSelector } from '@reduxjs/toolkit';

// Slice
export const createSliceList = (tag) => createSlice({
    name: tag,
    initialState: {
        pagination: {
            page: 0,
            rowsPerPage: 50,
        },
        sort: {
            orderBy: 'created_at',
            order: 'desc',
        },
    },
    reducers: {
        pagination: (state, action) => {
            state.pagination = {
                page: action.payload.page,
                rowsPerPage: action.payload.rowsPerPage
            }
        },
        sort: (state, action) => {
            state.sort = { order: action.payload.order, orderBy: action.payload.orderBy }
        },
        reset: (state) => {
            state.pagination = {
                page: 0,
                rowsPerPage: 50,
            }
            state.sort = {
                orderBy: 'created_at',
                order: 'desc',
            }
        },
        selected: (state, action) => {
            const selectedIndex = state.selected.indexOf(action.payload);
            let newSelected = [];

            if (selectedIndex === -1) {
                newSelected = newSelected.concat(state.selected, action.payload);
            } else if (selectedIndex === 0) {
                newSelected = newSelected.concat(state.selected.slice(1));
            } else if (selectedIndex === state.selected.length - 1) {
                newSelected = newSelected.concat(state.selected.slice(0, -1));
            } else if (selectedIndex > 0) {
                newSelected = newSelected.concat(
                    state.selected.slice(0, selectedIndex),
                    state.selected.slice(selectedIndex + 1)
                );
            }

            state.selected = newSelected
        },
    }
})


// Selector
export const createSelectorList = (tag, transform = (data) => data) => createSelector(
    (state) => _get(state, `${tag}.data`),
    (state) => _get(state, `${tag}.list`),
    ({ meta }, { pagination, sort }) => transform({                
        meta,
        pagination,
        sort,
    })
);