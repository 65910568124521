import { PieChart, Pie, Cell, Tooltip, Label, ResponsiveContainer } from 'recharts';
import CustomTooltip from '../tooltip';
import { Box } from '@mui/material';
import Loading from '../../common/loading';
import _sumBy from 'lodash/sumBy'

const Chart = ({
    title,
    data,
    loading
}) => {

    return (
        <Box width={1}>
            {loading && <Loading type='progress' />}
            {data?.chart &&
                <ResponsiveContainer height={450}>
                    <PieChart>
                        <Pie data={data.chart} innerRadius={120} outerRadius={160} paddingAngle={5} dataKey="value">
                            {data.chart.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry.color} />
                            ))}
                            <Label value={`${title}: ${_sumBy(data.chart, (item) => item.value)}`} offset={0} position="center" />
                        </Pie>

                        <Tooltip content={<CustomTooltip />} cursor={false} />
                    </PieChart>
                </ResponsiveContainer>
            }
        </Box>
    )
}

export default Chart