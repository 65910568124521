import { useState } from "react";
import { useDispatch } from "react-redux";

export const useTableFooter = (initialValue, action) => {

    const dispatch = useDispatch();
    const [value, setValue] = useState(initialValue);

    const onPageChange = (_, newPage) => {
        setValue({ rowsPerPage: value.rowsPerPage, page: newPage })
    }

    const onRowsPerPageChange = (event) => {
        setValue({ page: 0, rowsPerPage: Number(event.target.value) })
    }

    const fetchPagination = () => {
        dispatch(action({ page: value.page, rowsPerPage: value.rowsPerPage }));
    }

    return {
        rowsPerPage: value.rowsPerPage,
        page: value.page,
        onPageChange,
        onRowsPerPageChange,
        fetchPagination,
    };
}