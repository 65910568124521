import { useFormContext } from "react-hook-form";
import Button from "../../form/components/button"
import ClearIcon from '@mui/icons-material/Clear';
import { useCallback } from "react";

const ResetButton = ({
    onReset = () => { },
    defaultValues = null
}) => {

    const { reset } = useFormContext()

    const onClick = useCallback(() => {
        onReset()
        reset(defaultValues)
    }, [onReset, reset, defaultValues])


    return (
        <Button variant="outlined" color="error" size='small' startIcon={<ClearIcon />} onClick={onClick}>
            Cancella
        </Button>
    )
}

export default ResetButton