import { useCallback, isValidElement, Children, cloneElement } from 'react';
import { useReset } from '../../../hooks/password/useReset';
import { useSelector } from 'react-redux';

const ResetContainer = ({
    api,
    selector,
    children
}) => {

    const { loading, data, postData, error } = useReset(api.reset)
    const { data: user } = useSelector(selector)

    const onReset = useCallback((values) => {        
        postData(null, null, values)
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 10);
    }, [postData]);

    const childWithProps = Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child, { loading, data, error, onSubmit: onReset, disabled: loading, user });
        }
        return child;
    });

    return childWithProps
};

export default ResetContainer