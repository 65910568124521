import { Stack, Typography, Chip, Box } from "@mui/material"
import _ from 'lodash'
import dayjs from 'dayjs';

export const CellType = {
    product: 'product',
    email: 'email',
    date: 'date',
    sku: 'sku',
    status: 'status',
    code: 'code',
    default: 'default',
    active: 'active'
}

export const CellVariant = {
    row: 'row',
    head: 'head'
}

const TableCell = ({
    variant = CellVariant.row,
    type = CellType.default,
    value
}) => {

    if (variant === CellVariant.head) {
        return (
            <Typography variant='tableHead' >
                {value}
            </Typography>
        );
    }

    switch (type) {

        case CellType.email:
            return (
                <Typography variant='tableRow' sx={{ fontWeight: 'bold' }} color={'primary'}>
                    {value}
                </Typography>
            )

        case CellType.code:
            return (
                <Typography variant='tableRow' sx={{ fontWeight: 'bold' }} color={'primary'}>
                    {value}
                </Typography>
            )

        case CellType.date:
            return (
                <Typography variant='tableRow'>
                    {dayjs(value).format('DD/MM/YYYY')}
                </Typography>
            )

        case CellType.sku:
            return (
                <Stack>
                    <Typography color={'text.secondary'} fontWeight={'bold'} variant='tableRow'>{_.head(_.split(value, '-'))}</Typography>
                    <Typography color={'text.secondary'} variant='tableRow'>{_.last(_.split(value, '-'))}</Typography>
                </Stack>
            )

        case CellType.product:
            return (
                <Typography color={'text.secondary'} fontWeight={'bold'} variant='tableRow'>{value}</Typography>
            )

        case CellType.status:
            return (
                <Chip size="small" color={value?.color || 'primary'} label={value?.label || '-'} />
            )

        case CellType.active:
            return (
                <Stack justifyContent={'center'} width={1} alignItems={'center'}>
                    <Box sx={{ borderRadius: 2, height: 16, width: 16, backgroundColor: value === true ? 'success.main' : 'error.main' }} />
                </Stack>                
            )

        default:
            return (
                <Typography variant='tableRow'>
                    {value ? value : '-'}
                </Typography>
            );
    }


    return null
}

export default TableCell