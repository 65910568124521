import _get from 'lodash/get'


export const API = {
	list: {
		path: '/subscription-redeem-codes-search',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},
	count: {
		path: '/subscription-redeem-codes-count',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},
	detail: {
		path: '/subscription-redeem-codes/{{id}}',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},
	create: {
		path: '/subscription-redeem-codes/bulk-create',
		method: 'POST',
		get: null,
		auth: true,
		fallback: null,
		adapter: (body) => ({
			...body,
			newspaper: _get(body, "newspaper.id"),
			site_and_reader: _get(body, 'site_and_reader.value'),
			license_type: _get(body, 'license_type.value')
		})
	},
	delete: {
		path: '/subscription-redeem-codes/{{id}}',
		method: 'DELETE',
		get: null,
		auth: true,
		fallback: null,
	},
	edit: {
		path: '/subscription-redeem-codes/{{id}}',
		method: 'PUT',
		get: null,
		auth: true,
		fallback: null,
		adapter: (body) => ({
			customer_email: _get(body, "customer_email"),
			customer_name: _get(body, "customer_name"),
			determine_management: _get(body, "determine_management"),
			invoice_number: _get(body, "invoice_number"),
			customer_purchase_amount: _get(body, "customer_purchase_amount"),
			license_type: _get(body, "license_type.value"),
		})
	},
};

export const module = {
    ...API
}