import React from 'react';
import MUITypography from '@mui/material/Typography';
import MUIBreadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

const BreadcrumbsComponent = ({
    links = []
}) => {

    return (
        <Box sx={{ marginBottom: 2 }}>
            <MUIBreadcrumbs>
                {links.map((link, index) =>
                    index === links.length - 1 ?
                        <MUITypography key={link.to} color="text.primary">{link.label}</MUITypography> :
                        <Link to={link.to} key={link.to} replace={true}>
                            <MUITypography fontWeight={'bold'} color="text.primary">{link.label}</MUITypography>
                        </Link>
                )}
            </MUIBreadcrumbs>
        </Box>
    );
}

export default BreadcrumbsComponent