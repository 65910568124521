import { Box } from "@mui/material";
import React from "react";
import Button from "../form/components/button";
import { Form } from "../form";
import EditIcon from '@mui/icons-material/Edit';

const Update = ({
    defaultValues,
    disabled = false,
    onSubmit = () => { },
    fields,
    schema,
}) => {

    return (
        <Form fields={fields} schema={schema} defaultValues={defaultValues} onSubmit={onSubmit}>
            <Box display={'flex'} justifyContent='flex-end'>
                <Button type="submit" variant="outlined" startIcon={<EditIcon />} sx={{ mt: 1, mb: 2 }} disabled={disabled}>
                    Modifica
                </Button>
            </Box>
        </Form>
    )
}

export default Update