import { Stack, Divider, Chip, Grid } from "@mui/material"
import dayjs from 'dayjs';
import { useMemo, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import Collapse from '@mui/material/Collapse';
import Text from "../../../../components/detail/text";
import Row from "../../../../components/detail/row";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const SubscriptionCard = ({
    subscription,
    open = false
}) => {


    const enabled = useMemo(() => subscription.status === 'active', [subscription.status])
    const [checked, setChecked] = useState(open);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const dates = useMemo(() => [
        ['Inizio', dayjs(subscription.current_period_start).format('DD/MM/YYYY')],
        ['Fine', dayjs(subscription.canceled_at || subscription.current_period_end).format('DD/MM/YYYY')]        
    ], [subscription])

    const info = useMemo(() => [
        ['Tipo', subscription.subscription_authorization_type],
        ['SKU', subscription.sku],
        ['Stripe ID', subscription.stripe_id],
        ['Price ID', subscription.price_id],
        ['Customer Price ID', subscription.stripe_customer?.stripe_id],
    ], [subscription])

    const others = useMemo(() => [
        ['Attivo', subscription.is_active === true ? 'SI' : 'NO'],
        ['Rinnovo Automatico', subscription.cancel_at_period_end === true ? 'NO' : 'SI'],
        ['Codice Promozionale', subscription.subscription_redeem_code?.code],
        ['Note', subscription.cancellation_details_comment]
    ], [subscription])



    return (
        <Stack divider={<Divider />}>

            <Stack direction={'row'} justifyContent={'space-between'} alignContent={'center'} padding={1} onClick={handleChange} sx={{ cursor: 'pointer' }}>
                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                    {checked ? <RemoveCircleOutlineIcon color="primary" /> : <AddCircleOutlineIcon color="primary"/>}
                    <Text variant="subtitle1" enabled={true} title={`${subscription.product_sku?.name || subscription.sku} ${subscription.can_download_pdf === true ? '(PDF)' : ''}  (${dayjs(subscription.created_at).format('DD/MM/YYYY')})`} />
                </Stack>
                <Chip label={subscription.status} color={enabled ? "success" : "error"} variant={enabled ? "filled" : "filled"} />
            </Stack>

            <Collapse in={checked}>
                <Stack spacing={1} divider={<Divider />} padding={1}>
                    <Grid container>
                        {dates.map((item) => <Row key={uuidv4()} item={<Text enabled={true} variant="body1" title={item[0]} value={item[1]} />} />)}
                    </Grid>
                    <Grid container>
                        {info.map((item) =>
                            <Row key={uuidv4()} item={
                                <Text enabled={true} variant="body2" title={item[0]} value={item[1]} />
                            } />)}
                    </Grid>

                    <Grid container>
                        {others.map((item) =>
                            <Row key={uuidv4()} item={
                                <Text enabled={true} variant="body2" title={item[0]} value={item[1]} />
                            } />)}
                    </Grid>
                </Stack>
                <Divider />
            </Collapse>
        </Stack>
    )
}

export default SubscriptionCard