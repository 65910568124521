import { DynamicControl } from './control';
import { Grid } from '@mui/material';
import Section from './section';
import _get from 'lodash/get';
import _isNull from 'lodash/isNull'
import _isUndefined from 'lodash/isUndefined'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _compact from 'lodash/compact'
import _isEmpty from 'lodash/isEmpty'
import { v4 as uuidv4 } from 'uuid';
import { Fragment, useMemo } from 'react';
import Loading from '../common/loading';
import Alert from '../common/alert';



const DetailComponent = ({
	fields,
	render,
	data,
	loading,
	error
}) => {

	const sections = useMemo(() => {
		return _filter(fields, ({ items }) => {
			const values = _compact(_map(items, ({ key }) => _get(data, key)))
			return !_isEmpty(values)
		})
	}, [fields, data])

	return (
		<Fragment>
			{loading && <Loading />}
			{error && <Alert severity='error'>{error}</Alert>}
			<Grid container>
				{sections.map((section) => (
					<Grid item key={uuidv4()} xs={section.xs ? section.xs : 12} sm={section.sm}>
						<Section type={section.type} label={section.label} icon={section.icon}>
							<Grid container spacing={2}>
								{section.items.map(
									({ xs, sm, md, lg, key, label, ...d }) => {
										return (
											_isNull(_get(data, key)) || _isUndefined(_get(data, key)) ? null :
												<Grid item xs={xs} sm={sm} md={md} lg={lg} key={uuidv4()}>
													{render(key, _get(data, key), label) || <DynamicControl {...d} value={_get(data, key)} />}
												</Grid>
										)
									}
								)}
							</Grid>
						</Section>
					</Grid>
				))}				
			</Grid>
		</Fragment>
	);
};

export default DetailComponent