import { menuSlice as createMenuSlice } from '../../../core/menu/slice'
import { MenuConf } from '../conf/Menu.conf'

export const tag = 'menu'

// REDUCERS
const menuSlice = createMenuSlice(`${tag}/menu`, MenuConf.initialState)

export const reducer = menuSlice.reducer


// ACTIONS
export const actions = {
    ...menuSlice.actions,
}
