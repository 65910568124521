import { Stack } from "@mui/material";

const CardContainer = ({
    children
}) => {

    return (
        <Stack spacing={0.5} sx={{ padding: 2, paddingBottom: 1, backgroundColor: 'white', borderRadius: 4, boxShadow: 'rgba(0, 0, 0, 0.04) 0px 5px 22px, rgba(0, 0, 0, 0.03) 0px 0px 0px 0.5px' }}>
            {children}
        </Stack>
    )
}

export default CardContainer