import { Paper } from "@mui/material"
import { useNavigate } from "react-router-dom";
import SearchFilterContainer from "../../containers/searchfilter";
import ListContainer from "../../containers/list";
import SearchContainer from "../../containers/search";
import FilterContainer from "../../containers/filter";
import { module as purchases } from '../../module/purchases/redux'
import { module as list } from '../../module/purchases/views/list'
import { module as filter } from '../../module/purchases/views/filter'
import { module as search } from '../../module/purchases/views/search'
import { module as api } from '../../module/purchases/api'
import Table from "../../components/table";
import SearchBar from "../../components/search";
import Filter from "../../components/filter";


const ListPage = () => {

    const navigate = useNavigate()
    const goToDetail = (id) => {
        navigate(`/purchases/${id}`)
    }

    return (
        <Paper sx={{ padding: 2, marginTop: 2 }}>
            <SearchFilterContainer
                selector={purchases.selectors.filter}
                search={
                    <SearchContainer selector={purchases.selectors.search} slice={purchases.slices.all.search}>
                        <SearchBar placeholder={search.placeholder} />
                    </SearchContainer>
                }
                filter={
                    <FilterContainer selector={purchases.selectors.filter} slice={purchases.slices.all.filter}>
                        <Filter conf={filter} />
                    </FilterContainer>
                } />
            <ListContainer selector={purchases.selectors.all} slice={purchases.slices.all} api={api}>
                <Table onClick={(row) => goToDetail(row.id)} conf={list} />
            </ListContainer>
        </Paper>
    )
}

export default ListPage