export const API = {
    totals: {
        subscriptions: {
            detail: {
                path: '/subscriptions/count',
                method: 'GET',
                get: null,
                auth: true,
                fallback: null
            }
        },
        purchases: {
            detail: {
                path: '/purchases/count',
                method: 'GET',
                get: null,
                auth: true,
                fallback: null
            }
        },
        profiles: {
            detail: {
                path: '/profiles/count',
                method: 'GET',
                get: null,
                auth: true,
                fallback: null
            }
        },
    },
    charts: {
        activeSubscriptions: {
            list: {
                path: '/stats-active-subscriptions',
                method: 'GET',
                get: null,
                auth: true,
                fallback: null,
            },
            count: {
                path: '/subscriptions/count',
                method: 'GET',
                get: null,
                auth: true,
                fallback: null,
            },
        },
        subscriptions: {
            list: {
                path: '/subscriptions',
                method: 'GET',
                get: null,
                auth: true,
                fallback: null,
            },
            count: {
                path: '/subscriptions/count',
                method: 'GET',
                get: null,
                auth: true,
                fallback: null,
            },
        },
        purchases: {
            list: {
                path: '/purchases',
                method: 'GET',
                get: null,
                auth: true,
                fallback: null,
            },
            count: {
                path: '/purchases/count',
                method: 'GET',
                get: null,
                auth: true,
                fallback: null,
            },
        },
        profiles: {
            list: {
                path: '/profiles',
                method: 'GET',
                get: null,
                auth: true,
                fallback: null
            },
            count: {
                path: '/profiles/count',
                method: 'GET',
                get: null,
                auth: true,
                fallback: null,
            },        
        }
    }
};

export const module = {
    ...API
}