import { createSelector } from "@reduxjs/toolkit";
import { createSelectorList } from "../../../core/list/redux";
import { createSelectorSearch } from "../../../core/search/redux";
import { createSelectorFilter } from "../../../core/filter/redux";
import { createSelectorData } from "../../../core/data/redux";
import { createSelectorQuery } from "../../../core/query";
import { createSelectorDetail } from "../../../core/detail/redux";
import { createSliceData } from '../../../core/data/redux';
import { createSliceDetail } from '../../../core/detail/redux';
import { createSliceFilter } from '../../../core/filter/redux';
import { createSliceList } from '../../../core/list/redux';
import { createSliceSearch } from '../../../core/search/redux';
import { FilterConf } from '../conf/filter';
import { QueryConf } from "../conf/query";
import { EditConf } from "../conf/edit";
import { DetailConf } from "../conf/detail";

const tag = 'coupons'

export const createSelectors = (tag) => ({    
    search: createSelectorSearch(`${tag}.all`),
    filter: createSelectorFilter(`${tag}.all`),    
    detail: createSelectorDetail(`${tag}.detail`, DetailConf.set),
    edit: createSelectorDetail(`${tag}.detail`, EditConf.set),
    all: createSelector([
        createSelectorList(`${tag}.all`),
        createSelectorData(`${tag}.all`),
        createSelectorQuery(`${tag}.all`, QueryConf.get),
    ],(list, data, query) => ({ list, data, query }))
})


const createSlices = (tag) => ({
	detail: createSliceDetail(`${tag}/detail`),
	all: {
		data: createSliceData(`${tag}/all/data`),
		list: createSliceList(`${tag}/all/list`),
		search: createSliceSearch(`${tag}/all/search`),
		filter: createSliceFilter(`${tag}/all/filter`, FilterConf.initialState),
	}
})

export const module = {
    tag,
    selectors: createSelectors(tag),
    slices: createSlices(tag)
}