export const API = {
	list: {
		path: '/purchases',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},
	count: {
		path: '/purchases/count',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},
	detail: {
		path: '/purchases/{{id}}',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},		
};

export const module = {
    ...API
}