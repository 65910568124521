import _get from 'lodash/get'
import { createSelector, createSlice } from '@reduxjs/toolkit';

// Slice
export const createSliceData = (tag) => createSlice({
    name: tag,
    initialState: {
        loading: false,
        error: null,
        data: [],
        meta: null,
    },
    reducers: {
        fetch: (state) => state,
        success: (state, action) => {            
            state.data = action.payload.data
            state.meta = { pagination: { total: action.payload.meta } }
            state.loading = false
            state.error = null
        },
        loading: (state) => {
            state.loading = true
            state.error = null
            state.data = []
            state.meta = null
        },
        error: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.data = []
            state.meta = null
        }
    }
})


// Selector
export const createSelectorData = (tag, transform = (data) => data) => createSelector(
    (state) => _get(state, `${tag}.data`),
    (data) => transform(data)
)