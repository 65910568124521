import { createSelectorUser, createSliceUser } from '../../../core/user/redux'

const tag = 'user'

const createSelectors = (tag) => ({
    user: createSelectorUser(tag),
})

const createSlices = (tag) => ({
	data: createSliceUser(`${tag}`),	
})


export const module = {
    tag,
    selectors: createSelectors(tag),
    slices: createSlices(tag)
}