import { call, put } from 'redux-saga/effects'
import { fetchAPI } from '../../../api/request';

export function* loginSaga(tag, action) {

    const { api, body } = action.payload

    try {
        yield put({ type: `${tag}/loading`})
        const response = yield call(fetchAPI, api, null, null, body, null);
        yield put({
            type: `${tag}/success`,
            payload: response
        })
    } catch (e) {
        yield put({
            type: `${tag}/error`,
            payload: 'Username o Password errati, riprova'
        })
    }
}