import _find from 'lodash/find';
import { PurchasesStatus } from '../../../../utility/Datasource.utility';
import TableCell, { CellType, CellVariant } from '../../../../components/table/cell';

export const ListConf = {	
	rowsPerPageOptions: [ 50, 100, 150, 200],
	columns: [			
		{
			id: 'product_sku.name',
			key: 'product_sku.name',
			sort_key: 'product_sku.name',			
			label: 'Prodotto',
		},
		{
			id: 'profile.email',
			key: 'profile.email',
			sort_key: 'profile.email',			
			label: 'Email',
		},
		{
			id: 'date',
			key: 'date',
			sort_key: 'date',			
			label: 'Data',
		},		
		{
			id: 'issue_sku.date',
			key: 'issue_sku.date',
			sort_key: 'issue_sku.date',			
			label: 'Copia del',
		},		
		{
			id: 'status',
			key: 'status',
			sort_key: 'status',			
			label: 'Stato',
			sortable: false
		}		
	],
	render: {
		formatHead: (_, value) => {
			return (
				<TableCell variant={CellVariant.head} value={value} />
			);
		},
		formatValue: (key, value) => {
			switch (key) {
				case 'product_sku.name':
					return (
						<TableCell type={CellType.sku} value={value} />
					)
				case 'profile.email':
					return (
						<TableCell type={CellType.email} value={value} />
					)
				case 'date':
				case 'issue_sku.date':
					return (
						<TableCell type={CellType.date} value={value} />
					)
				case 'status':
					const status = _find(PurchasesStatus, (o) => o.id === value)
					return (
						<TableCell type={CellType.status} value={status} />					
					)
				default:
					return (
						<TableCell type={CellType.default} value={value} />
					);
			}
		},
	},
	actions:{
		download: false
	}
};

export const module = {
	...ListConf
}
