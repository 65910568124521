import { Box, Divider, Grid, Stack, Typography } from "@mui/material"
import DetailContainer from "../containers/detail";
import CardComponent from "../components/card";
import Filter from "../components/filter";
import FilterContainer from "../containers/filter";
import { module as filter } from "../module/stats/views/filter";
import { module as stats } from '../module/stats/redux'
import { module as api } from '../module/stats/api'
import { module as totals } from '../module/stats/views/totals'
import DataContainer from "../containers/data";
import PieChart from "../components/charts/pie";
import StackedChart from "../components/charts/stacked";
import ListPurchases from "../module/stats/components/list/purchases";
import ListSubscriptions from "../module/stats/components/list/subscriptions";
import { v4 as uuidv4 } from 'uuid';
import Legend from "../components/charts/legend";

const StatsCard = ({
    tag,
    query
}) => {

    return (
        <DetailContainer selector={stats.selectors.totals[tag]} slice={stats.slices.totals[tag]} api={api.totals[tag]} query={{ ...query }}>
            <CardComponent conf={totals[tag].conf} render={totals[tag].formatValue} />
        </DetailContainer>
    )
}

const StatsChart = ({
    tag,
    title,
    showFilter = true,
    children
}) => {


    return (
        <Box width={1}>
            <Typography color={'text.secondary'} fontWeight={'bold'} textAlign={'center'}>{title.toUpperCase()}</Typography>
            <Stack bgcolor={'white'} divider={<Divider />}>
                {showFilter &&
                    <FilterContainer selector={stats.selectors.charts.filter[tag]} slice={stats.slices.charts[tag].filter}>
                        <Filter conf={filter} />
                    </FilterContainer>}
                <Box padding={2}>
                    {children}
                </Box>
            </Stack>
        </Box>
    )
}


const StatsChartsSubscriptions = ({
    loading,
    data,
}) => {

    return (
        <Stack divider={<Divider />} spacing={1}>
            <Legend data={data} />
            <Grid container>
                <Grid item xs={12} sm={5}>
                    <PieChart title='Totale' data={data} loading={loading} />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <StackedChart data={data} loading={loading} />
                </Grid>
            </Grid>
            <ListSubscriptions data={data} loading={loading} />
        </Stack>
    )
}

const AllStatsChartsSubscriptions = ({
    loading,
    data,
}) => {

    return (
        <Stack divider={<Divider />} spacing={1}>
            <Legend data={data} />
            <PieChart title='Totale' data={data} loading={loading} />
            <ListSubscriptions data={data} loading={loading} />
        </Stack>
    )
}

const StatsChartsPurchases = ({
    loading,
    data,
}) => {

    return (
        <Stack divider={<Divider />} spacing={1}>
            <Legend data={data} />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={5}>
                    <PieChart title='Totale' data={data} loading={loading} />
                </Grid>
                <Grid item xs={12} sm={7}>
                    <StackedChart data={data} loading={loading} />
                </Grid>
            </Grid>
            <ListPurchases data={data} loading={loading} />
        </Stack>
    )
}

const Cards = [{
    tag: 'profiles',
    query: { _where: { _or: [{ 'deleted_null': true }, { 'deleted_eq': false }] } }
}, {
    tag: 'subscriptions',
    query: { _where: { 'is_active_eq': true, } }
}, {
    tag: 'purchases',
    query: { _where: { 'status_eq': 'succeeded', } }
}]

const HomePage = () => {


    return (
        <Stack sx={{ width: '100%', height: '100%' }} spacing={3}>

            <Grid container spacing={2}>
                {Cards.map(({ tag, query }) =>
                    <Grid item xs key={uuidv4()}>
                        <StatsCard tag={tag} query={query} />
                    </Grid>
                )}
            </Grid>

            <StatsChart tag={'allsubscriptions'} title={'abbonamenti attivi'} showFilter={false}>
                <DataContainer selector={stats.selectors.charts['allsubscriptions']} slice={stats.slices.charts['allsubscriptions']} api={api.charts['activeSubscriptions']}>
                    <AllStatsChartsSubscriptions />
                </DataContainer>
            </StatsChart>


            <StatsChart tag={'subscriptions'} title={'nuovi abbonamenti'}>
                <DataContainer selector={stats.selectors.charts['subscriptions']} slice={stats.slices.charts['subscriptions']} api={api.charts['subscriptions']}>
                    <StatsChartsSubscriptions />
                </DataContainer>
            </StatsChart>

            <StatsChart tag={'purchases'} title={'copie singole'}>
                <DataContainer selector={stats.selectors.charts['purchases']} slice={stats.slices.charts['purchases']} api={api.charts['purchases']}>
                    <StatsChartsPurchases />
                </DataContainer>
            </StatsChart>

        </Stack>
    )
}

export default HomePage