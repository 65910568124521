import _get from 'lodash/get'

export const API = {
	list: {
		path: '/profiles',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},
	count: {
		path: '/profiles/count',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},
	profile: {
		detail: {
			path: '/profiles?auth0={{id}}',
			method: 'GET',
			get: null,
			auth: true,
			fallback: null,
		},
		reset: {
			path: '/user-reset-password',
			method: 'POST',
			get: null,
			auth: true,
			fallback: null,
			adapter: (body) => ({
				email: _get(body, "email"),
				newspaper_id: _get(body, "newspaper.id")
			})
		}
	},
	subscriptions: {
		detail: {
			path: '/subscriptions?profile.auth0_eq={{id}}',
			method: 'GET',
			get: null,
			auth: true,
			fallback: null,
		}
	},
	purchases: {
		detail: {
			path: `/purchases?profile.auth0_eq={{id}}`,
			method: 'GET',
			get: null,
			fallback: [],
			auth: true
		}
	},	
};

export const module = {
    ...API
}