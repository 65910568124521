import { Box } from "@mui/material"
import { useParams } from "react-router-dom";
import BreadcrumbsComponent from "../../components/breadcrumbs";
import EditContainer from "../../containers/edit";
import { module as coupons } from '../../module/coupons/redux'
import { module as api } from '../../module/coupons/api'
import { module as edit } from '../../module/coupons/views/edit'
import Update from "../../components/edit";
import { Fragment } from "react";

const links = [{
    to: '/coupons',
    label: 'Coupons'
}]

const EditPage = () => {

    const { id } = useParams();

    return (
        <Fragment>
            <BreadcrumbsComponent links={[...links, { to: `/coupons/${id}`, label: id }, { to: id, label: 'Modifica' }]} />
            <Box sx={{ width: '100%', padding: 1, borderRadius: 1, backgroundColor: 'background.paper' }}>
                <EditContainer selector={coupons.selectors.edit} slice={coupons.slices.detail} api={api} params={{ id }}>
                    <Update fields={edit.fields} schema={edit.schema} />
                </EditContainer>
            </Box>
        </Fragment>
    )
}

export default EditPage