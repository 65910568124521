import React from 'react';
import MuiDivider from '@mui/material/Divider';
import MuiTypography from '@mui/material/Typography'

import PropTypes from 'prop-types';

const Divider = ({ label }) => {
    return (
        <MuiDivider sx={{ mt: 2, mb: 2 }}>
            {label &&
                <MuiTypography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                    {label}
                </MuiTypography>}
        </MuiDivider>
    );
};

Divider.propTypes = {
    label: PropTypes.string,
};

export default Divider;
