import { Box } from "@mui/material"
import { useParams } from "react-router-dom";
import BreadcrumbsComponent from "../../components/breadcrumbs";
import DetailContainer from "../../containers/detail";
import { module as products } from '../../module/products/redux'
import { module as api } from '../../module/products/api'
import { module as views } from '../../module/products/views/detail'
import DetailComponent from "../../components/detail";
import { useSelector } from "react-redux";

const links = [{
    to: '/products',
    label: 'Prodotti'
}]


const DetailPage = () => {

    const { id } = useParams();
    const { data } = useSelector(products.selectors.detail.product)

    return (
        <Box sx={{ width: '100%', height: '100%', padding: 1 }}>
            <BreadcrumbsComponent links={[...links, { to: `/products/${id}`, label: id }, { to: id, label: 'Dettaglio' }]} />
            <DetailContainer selector={products.selectors.detail.product} slice={products.slices.detail.product} api={api.product} params={{ id }}>
                <DetailComponent fields={views.product.fields} render={views.product.formatValue} />
            </DetailContainer>

            {data?.product && data?.product.free === false &&
                <DetailContainer selector={products.selectors.detail.prices} slice={products.slices.detail.prices} api={api.prices} params={{ id_stripe: data?.product.id_stripe, slug: data?.product.newspaper.slug }}>
                    <DetailComponent fields={views.prices.fields} render={views.prices.formatValue} />
                </DetailContainer>
            }
        </Box>
    )
}

export default DetailPage