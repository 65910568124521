import { FormProvider, useForm } from 'react-hook-form';
import { DynamicControl } from './dynamic';
import FormComponent from './components/form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Divider, Grid } from '@mui/material';
import { useEffect } from 'react';

export const Form = ({
	fields,
	schema,
	defaultValues,
	onSubmit = () => { },
	children,
}) => {
	const methods = useForm({
		resolver: yupResolver(schema),
		defaultValues: defaultValues,
	});


	useEffect(() => {
		methods.reset(defaultValues);
		// eslint-disable-next-line
	}, [defaultValues]);

	return (
		<FormProvider {...methods}>
			<FormComponent onSubmit={methods.handleSubmit(onSubmit)}>
				<Grid container spacing={1}>
					{fields.map(({ xs, sm, ...d }, i) => (
						<Grid item xs={xs} sm={sm} key={i}>
							<DynamicControl {...d} />
						</Grid>
					))}
					<Grid item xs={12}>
						<Divider sx={{ marginBottom: 2 }} />
						{children}
					</Grid>
				</Grid>
			</FormComponent>
		</FormProvider>
	);
};