import { Box, Stack } from "@mui/material"
import { useParams } from "react-router-dom";
import BreadcrumbsComponent from "../../components/breadcrumbs";
import DeleteComponent from '../../components/delete'
import DetailComponent from "../../components/detail";
import DetailContainer from "../../containers/detail";
import UpdateButton from "../../components/actions/update";
import DeleteContainer from "../../containers/delete";
import { useSelector } from "react-redux";
import { module as coupons } from '../../module/coupons/redux'
import { module as api } from '../../module/coupons/api'
import { module as detail } from '../../module/coupons/views/detail'



const links = [{
    to: '/coupons',
    label: 'Coupons'
}]

const DetailPage = () => {

    const { id } = useParams();
    const { data } = useSelector(coupons.selectors.detail)

    return (
        <Box sx={{ width: '100%', height: '100%', padding: 1 }}>
            <BreadcrumbsComponent links={[...links, { to: `/coupons/${id}`, label: id }, { to: id, label: 'Dettaglio' }]} />            
            <DetailContainer selector={coupons.selectors.detail} slice={coupons.slices.detail} api={api} params={{ id }}>
                <DetailComponent fields={detail.fields} render={detail.formatValue}/>
            </DetailContainer>
            {data?.coupon?.redeemed === false &&
                <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} marginTop={1}>
                    <UpdateButton to={`/coupons/edit/${id}`} />
                    <DeleteContainer to='/coupons/' id={id} api={api}>
                        <DeleteComponent />
                    </DeleteContainer>    
                </Stack>
            }
        </Box>
    )
}

export default DetailPage