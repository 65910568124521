import { Stack, Collapse } from "@mui/material"

const SearchFilterComponent = ({
    search,
    filter,
    checked = false,
    icon
}) => {

    return (
        <Stack>
            <Stack spacing={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                {search}
                {icon}
            </Stack>
            <Collapse in={checked} sx={{ border: 1, borderColor: 'divider', mt: 1, mb: 1 }}>
                {filter}
            </Collapse>
        </Stack>
    )

}

export default SearchFilterComponent