import { useSelector } from "react-redux";
import { useEffect, isValidElement, Children, cloneElement } from "react";
import { useTableHead } from "../../hooks/table/useTableHead";
import { useTableFooter } from "../../hooks/table/useTableFooter";
import { useFetchList } from "../../hooks/table/useFetchList";
import _mapValues from 'lodash/mapValues'
import { useDownloadCSV } from "../../hooks/table/useDownloadCSV";

const ListContainer = ({
    selector,
    slice,
    api,
    children
}) => {



    const { list: { meta, sort, pagination }, data: { data, loading, error }, query } = useSelector(selector)
    const actions = _mapValues(slice, (value) => value.actions ? _mapValues(value.actions, (action) => action) : {});
    const { fetchSort, ...sortProps } = useTableHead({ ...sort }, actions.list.sort)
    const { fetchPagination, ...paginationProps } = useTableFooter({ ...pagination }, actions.list.pagination)
    const { fetchList } = useFetchList(actions.data.fetch, api)
    const { getCSV, loading: loadingCSV } = useDownloadCSV(api)

    useEffect(() => {
        fetchList(query)
    }, [query])

    useEffect(() => {
        fetchSort()
    }, [sortProps.order, sortProps.orderBy])

    useEffect(() => {
        fetchPagination()
    }, [paginationProps.rowsPerPage, paginationProps.page])


    const downloadCSV = () => {
        getCSV({ ...query, _limit: -1 })
    }

    const childWithProps = Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child, { loading, data, error, meta, sort, sortProps, paginationProps, downloadCSV, loadingCSV });
        }
        return child;
    });

    return childWithProps
}

export default ListContainer