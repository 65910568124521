import { Stack, Divider, Grid } from "@mui/material"
import dayjs from 'dayjs';
import { useMemo } from "react";
import { v4 as uuidv4 } from 'uuid';
import Text from "../../../../components/detail/text";
import Row from "../../../../components/detail/row";

const ProfileCard = ({
    profile
}) => {

    const dates = useMemo(() => [
        ['Data di Creazione', dayjs(profile.created_at).format('DD/MM/YYYY hh:mm')],
        ['Ultima modifica', dayjs(profile.updated_at).format('DD/MM/YYYY hh:mm')],
    ], [profile])

    const info = useMemo(() => [
        ['Email', profile.email],
        ['Auth0', profile.auth0],
        ['Stato', profile.deleted === true ? 'Disabled' : 'Active'],
    ], [profile])

    return (
        <Stack spacing={1} divider={<Divider />} padding={1}>
            <Grid container>
                {info.map((item) =>
                    <Row key={uuidv4()} item={
                        <Text enabled={true} variant="body1" title={item[0]} value={item[1]} />
                    } />)}
            </Grid>
            <Grid container>
                {dates.map((item) =>
                    <Row key={uuidv4()} item={
                        <Text enabled={true} variant="body1" title={item[0]} value={item[1]} />
                    } />)}
            </Grid>

        </Stack>
    )
}

export default ProfileCard