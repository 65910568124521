import { useState } from 'react';
import { fetchAPI } from '../../api/request';
import { useSelector } from "react-redux"
import { module as auth } from "../../module/user/redux";


export const useDownloadCSV = (api) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const { jwt } = useSelector(auth.selectors.user);

    const getCSV = (query) => {

        setLoading(true)
        fetchAPI(api.download, null, query, null, jwt)
            .then((response) => {
                setLoading(false)
                setError(null)                
                const csvData = new Blob([response], { type: 'text/csv' });
                const csvURL = URL.createObjectURL(csvData);
                const link = document.createElement('a');
                link.href = csvURL;
                link.download = `data.csv`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => {
                setLoading(false)
                setError(error)
            })

    }

    return { loading, error, getCSV };
}