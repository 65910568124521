import _get from 'lodash/get'
import { createSlice, createSelector } from '@reduxjs/toolkit';

// Slice
export const createSliceFilter = (tag, initialState) => createSlice({
    name: tag,
    initialState,
    reducers: {
        set: (state, action) => {
            state.value = { ...action.payload };
        }
    }
})

// Selector
export const createSelectorFilter = (tag, transform = (value) => value) => createSelector(
    (state) => _get(state, `${tag}.filter`),
    (value) => transform(value)
)