import { call, put } from 'redux-saga/effects'
import { fetchAPI } from "../../../api/request";

export function* fetchAll(tag, action) {

    const { api, count, params, query, body, token } = action.payload

    try {
        yield put({ type: `${tag}/loading` })
        const _data = yield call(fetchAPI, api, params, query, body, token);
        const _count = yield call(fetchAPI, count, null, query, null, token);
        yield put({
            type: `${tag}/success`,
            payload: {
                data: _data,
                meta: _count
            }
        })
    } catch (e) {
        console.log(e)
        yield put({
            type: `${tag}/error`,
            payload: "Errore di caricamento. Riprova più tardi o contatta l'assistenza"
        })
    }
}