import React from 'react';
import Error from '../error';
import { useController, useFormContext } from 'react-hook-form';
import { Stack as MUIStack, TextField as MUITextField } from '@mui/material';
import PropTypes from 'prop-types';

const TextField = ({ name, required, ...props }) => {
	const {
		control,
		formState: { errors },
	} = useFormContext();

	const {
		field: { ref, ...inputProps },
	} = useController({
		name: name,
		control: control,
		rules: { required },
	});

	return (
		<MUIStack spacing={0.5}>
			<MUIStack direction={'row'} spacing={2} alignItems={'center'}>
				{props.icon}
				<MUITextField
					error={!!errors[name]?.message}
					{...inputProps}
					inputRef={ref}
					{...props}
					required={required}
					fullWidth
					size="small"
				/>
			</MUIStack>
			<Error error={errors[name]?.message} />
		</MUIStack>
	);
};

TextField.propTypes = {
	name: PropTypes.string,
	required: PropTypes.bool,
};

export default TextField;
