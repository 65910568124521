import { v4 as uuidv4 } from 'uuid';
import PurchaseCard from "../../../purchases/components/detail";

const ProfilePurchases = ({
    purchases
}) => {

    return (
        purchases.map((purchase) =>
            <PurchaseCard purchase={purchase} key={uuidv4()} />
        )
    )
}

export default ProfilePurchases