import { Stack, Typography } from "@mui/material"
import MuiTableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';

const TableTotal = ({
    span = 0,
    value
}) => {


    return (
        <MuiTableHead>
            <MuiTableRow>
                <MuiTableCell colSpan={span} sx={{ backgroundColor: 'background.paper' }}/>
                <MuiTableCell sx={{ backgroundColor: 'background.paper' }}>
                    <Stack direction={'row'} spacing={0.5} justifyContent={'flex-end'}>
                        <Typography variant="subtitle1" color={'text.primary'} fontWeight={'bold'}>TOTALE:</Typography>
                        <Typography variant="subtitle1" color={'text.secondary'} >{value}</Typography>
                    </Stack>
                </MuiTableCell>
            </MuiTableRow>
        </MuiTableHead>
    )
}

export default TableTotal