import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmDialog = ({
    open = false,
    onClose = () => { },
    title = '',
    description = '',
    cancelAction = () => { },
    confirmAction = () => { }
}) => {


    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancelAction}>
                    Cancella
                </Button>
                <Button onClick={confirmAction} autoFocus>
                    Conferma
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog