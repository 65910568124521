export const SearchConf = {
    query: {
        keys: [{
            key: 'profile.email',            
            operator: 'contains'
        }]
    },
    initialState: {
        value: ''
    },
}