import * as yup from "yup";
import { API as newspapersAPI } from '../../../newspapers/api'
import { SubscriptionsTypes, SubscriptionsPeriods, SubscriptionsStatus, SubscriptionsPurchaseType } from '../../../../utility/Datasource.utility'

export const FilterConf = {
    schema: yup.object({}),    
    fields: [{
        id: "newspaper",
        name: "newspaper",
        type: "async_autocomplete",
        label: "Testata",
        api: newspapersAPI.select_with_sku,
        required: false,
        xs: 12,
        sm: 6
    },{
        id: "type_product",
        name: "type_product",
        type: "autocomplete",
        label: "Tipo di Prodotto",
        options: SubscriptionsTypes,
        required: false,
        xs: 12,
        sm: 6
    }, {
        id: "periodicity",
        name: "periodicity",
        type: "autocomplete",
        label: "Periodicità",
        options: SubscriptionsPeriods,
        required: false,
        xs: 12,
        sm: 4
    },{
        id: "status",
        name: "status",
        type: "autocomplete",
        label: "Stato Abbonamento",
        options: SubscriptionsStatus,
        required: false,
        xs: 12,
        sm: 4
    }, {
        id: "subscription_authorization_type",
        name: "subscription_authorization_type",
        type: "autocomplete",
        label: "Tipo di Acquisto",
        options: SubscriptionsPurchaseType,
        required: false,
        xs: 12,
        sm: 4
    },{
        id: "start_date",
        name: "start_date",
        type: "date",
        label: "Dal",        
        required: false,
        xs: 12,
        sm: 4       
    },{
        id: "end_date",
        name: "end_date",
        type: "date",
        label: "Al",        
        required: false,
        xs: 12,
        sm: 4      
    }]    
}

export const module = {
    ...FilterConf
}