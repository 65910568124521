import { Stack, Grid } from "@mui/material"
import dayjs from 'dayjs';
import { useMemo } from "react";
import { v4 as uuidv4 } from 'uuid';
import Text from "../../../../components/detail/text";
import Row from "../../../../components/detail/row";

const CustomerCard = ({
    coupon
}) => {

    const info = useMemo(() => [
        ['Email', coupon.customer_email || '-'],
        ['Name', coupon.customer_name || '-'],
        ['Data di acquisto', coupon.customer_purchase_date? dayjs(coupon.customer_purchase_date).format('DD/MM/YYYY') : '-'],        
        ['Numero di Determina', coupon.determine_management || '-'],
        ['Numero di Fattura', coupon.invoice_number || '-'],        
    ], [coupon])

    return (
        <Stack spacing={1}>
            <Grid container spacing={1}>
                {info.map((item) =>
                    <Row key={uuidv4()} item={
                        <Text enabled={true} variant="body1" title={item[0]} value={item[1]} />
                    } />)}
            </Grid>
        </Stack>
    )
}

export default CustomerCard