import { Box } from "@mui/material"
import { useParams } from "react-router-dom";
import BreadcrumbsComponent from "../../components/breadcrumbs";
import DetailContainer from "../../containers/detail";
import { module as purchases } from '../../module/purchases/redux'
import { module as api } from '../../module/purchases/api'
import { module as detail } from '../../module/purchases/views/detail'
import DetailComponent from "../../components/detail";

const links = [{
    to: '/purchases',
    label: 'Copie Singole'
}]

const DetailPage = () => {

    const { id } = useParams();

    return (
        <Box sx={{ width: '100%', height: '100%', padding: 1 }}>
            <BreadcrumbsComponent links={[...links, { to: `/purchases/${id}`, label: id }, { to: id, label: 'Dettaglio' }]} />
            <DetailContainer selector={purchases.selectors.detail} slice={purchases.slices.detail} api={api} params={{ id }}>
                <DetailComponent fields={detail.fields} render={detail.formatValue} />
            </DetailContainer>
        </Box>
    )
}

export default DetailPage