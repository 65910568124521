import React from 'react';
import MuiTableCell from '@mui/material/TableCell';
import MuiTableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';
import MuiTableSortLabel from '@mui/material/TableSortLabel';
import { v4 as uuidv4 } from 'uuid';

const TableHead = ({
	columns,
	sort,
	formatHead = () => { },
	onClick = () => { }
}) => {

	return (
		<MuiTableHead>
			<MuiTableRow>
				{columns.map(({ align = 'inherit', sortable = true, label, key, sort_key, padding = 'normal', size = 'medium', backgroundColor = 'background.default' }) => (
					<MuiTableCell
						key={uuidv4()}
						align={align}
						padding={padding}
						size={size}
						sx={{ backgroundColor }}>
						{sortable ?
							<MuiTableSortLabel active={sort.orderBy === key} direction={sort.order} onClick={() => onClick(sort_key)}>
								{formatHead(key, label)}
							</MuiTableSortLabel> :
							formatHead(key, label)
						}
					</MuiTableCell>
				))}
			</MuiTableRow>
		</MuiTableHead>
	);
};

export default TableHead;
