import * as yup from 'yup';
import { API as newspapers } from '../../../newspapers/api'

export const ResetConf = {
    schema: yup.object({
        newspaper: yup.object().nullable().required('Campo obbligatorio').typeError('Campo obbligatorio'),
        email: yup.string().required('Campo obbligatorio').email('Inserire una email valida').nullable(),
    }),
    defaultValues: {
        newspaper: null,
        email: null,
    },
    fields: [ {
        id: 'email',
        name: 'email',
        type: 'text',
        label: 'Email',
        disabled: true,
        required: true,
        xs: 6,
    }, {
        id: 'newspaper',
        name: 'newspaper',
        type: 'async_autocomplete',
        label: 'Testata',
        required: true,
        api: newspapers.select_with_id,
        xs: 6,
    }],
};

export const module = {
    ...ResetConf
}