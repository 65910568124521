import { fetchAll } from "../../../core/data/saga";
import { fetchDetail } from "../../../core/detail/saga";
import { createAction } from '@reduxjs/toolkit'

const tag = 'stats'

const createSaga = (tag) => {    
    return [{
        action: createAction(`${tag}/totals/profiles/fetch`),
        tag: `${tag}/totals/profiles`,
        saga: fetchDetail
    },{
        action: createAction(`${tag}/totals/subscriptions/fetch`),
        tag: `${tag}/totals/subscriptions`,
        saga: fetchDetail
    },{
        action: createAction(`${tag}/totals/purchases/fetch`),
        tag: `${tag}/totals/purchases`,
        saga: fetchDetail
    },{
        action: createAction(`${tag}/charts/profiles/data/fetch`),
        tag: `${tag}/charts/profiles/data`,
        saga: fetchAll
    },{
        action: createAction(`${tag}/charts/subscriptions/data/fetch`),
        tag: `${tag}/charts/subscriptions/data`,
        saga: fetchAll
    },{
        action: createAction(`${tag}/charts/all/subscriptions/data/fetch`),
        tag: `${tag}/charts/all/subscriptions/data`,
        saga: fetchAll
    },{
        action: createAction(`${tag}/charts/purchases/data/fetch`),
        tag: `${tag}/charts/purchases/data`,
        saga: fetchAll
    }]
}

export const module = {
    tag,    
    saga: createSaga(tag)
}