import ProfilesIcon from '@mui/icons-material/Group';
import SubscriptionsIcon from '@mui/icons-material/ShoppingCart';
import CanceledSubscriptionsIcon from '@mui/icons-material/RemoveShoppingCart';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ArticleIcon from '@mui/icons-material/Article';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CouponIcon from '@mui/icons-material/ConfirmationNumber';

const Dashboard = {
	id: 'dashboard',
	to: '/dashboard',
	title: 'Dashboard',
	icon: 'dashboard'
}

const Profiles = {
	id: 'profiles',
	to: '/profiles',
	title: 'Utenti',	
	icon: 'profiles'
}

const Products = {
	id: 'products',
	to: '/products',
	title: 'Prodotti',
	icon: 'products',
}

const Subscriptions = {
	id: 'subscriptions',
	to: '/subscriptions',
	title: 'Abbonamenti',
	icon: 'subscriptions',
}

const Canceled = {
	id: 'canceled',
	to: '/canceled',
	title: 'Cancellati/Annullati',
	icon: 'canceled',
}

const Purchases = {
	id: 'purchases',
	to: '/purchases',
	title: 'Copie Singole',
	icon: 'purchases',
}

const Coupons = {
	id: 'coupons',
	to: '/coupons',
	title: 'Coupons',	
	icon: 'coupons',
}

export const MenuConf = {
	initialState: {
		items: [],
		selected: null,
		open: false,
	},
};

export const getIcon = (icon) => {

	switch (icon) {
		case 'dashboard':
			return <DashboardIcon />

		case 'profiles':
			return <ProfilesIcon />

		case 'products':
			return <ArticleIcon />

		case 'subscriptions':
			return <SubscriptionsIcon />

		case 'purchases':
			return <NewspaperIcon />

		case 'coupons':
			return <CouponIcon />

			case 'canceled':
				return <CanceledSubscriptionsIcon />

		default:
			return null;
	}
}

export const drawerWidth = 240;

export const DrawerAdmin = {
	routes: [Dashboard, Profiles, Products, Subscriptions,Canceled, Purchases, Coupons]
}

export const DrawerDiffusione = {
	routes: [Coupons]
}