import _omit from 'lodash/omit'
import _get from 'lodash/get'


export const DetailConf = {
	set: ({ loading, error, data }) => {
		return {
			loading,
			error,
			data: {
				profile: _get(data, 'profile'),
				purchase: _omit(data, ['profile']),
			}
		}
	},	
};
