import { Fragment } from "react";
import TableContainer from "./container";
import TableTotal from "./total";
import TableHead from "./head";
import TableFooter from "./footer";
import TableBody from "./body";
import Loading from "../common/loading";
import Alert from "../common/alert";
import DownloadCSV from "./download";

const Table = ({
    loading,
    error,
    data,
    meta,
    sort,
    sortProps,
    conf,
    paginationProps,
    downloadCSV,
    loadingCSV,    
    onClick = () => { }
}) => {

    return (
        <Fragment>
            {loading && <Loading />}
            {error && <Alert severity='error'>{error}</Alert>}
            {data && conf.actions.download && <DownloadCSV onDownload={downloadCSV} loading={loadingCSV}/>}
            {data &&
                <TableContainer>
                    <TableTotal
                        span={conf.columns.length - 1}
                        value={meta?.pagination?.total || 0} />
                    <TableHead
                        columns={conf.columns}
                        formatHead={conf.render.formatHead}
                        sort={sort}
                        {...sortProps} />
                    <TableBody
                        rows={data}
                        columns={conf.columns}
                        formatValue={conf.render.formatValue}
                        onClick={onClick} />
                </TableContainer>}
            {meta &&
                <TableFooter
                    total={meta?.pagination?.total || 0}
                    rowsPerPageOptions={conf.rowsPerPageOptions}
                    {...paginationProps} />}
        </Fragment>
    )
}

export default Table