import _omit from 'lodash/omit'
import _find from 'lodash/find'
import _split from 'lodash/split'
import _last from 'lodash/last'
import _setWith from 'lodash/setWith'

const month = {
	price: '<%= tier.unit_amount / 100 %> €/mese',
	first: '<%= tier.unit_amount / 100 %> €/mese per <%= tier.up_to %> mesi',
	others: ' poi <%= tier.unit_amount / 100 %> €/mese per <%= tier.up_to %> mesi',
	last: ' poi <%= tier.unit_amount / 100 %> €/mese'
}

const six_month = {
	price: '<%= tier.unit_amount / 100 %> €/semestre',
	first: '<%= tier.unit_amount / 100 %> €/semestre per <%= tier.up_to %> semestre',
	others: ' poi <%= tier.unit_amount / 100 %> €/semestre per <%= tier.up_to %> semestre',
	last: ' poi <%= tier.unit_amount / 100 %> €/semestre'
}

const year = {
	price: '<%= tier.unit_amount / 100 %> €/anno',
	first: '<%= tier.unit_amount / 100 %> €/anno per <%= tier.up_to %> anno',
	others: ' poi <%= tier.unit_amount / 100 %> €/anno per <%= tier.up_to %> anni',
	last: ' poi <%= tier.unit_amount / 100 %> €/anno'
}

const day = {
	price: '<%= tier.unit_amount / 100 %> €',
}

const slugToValueMap = {
	'1m': month,
	'6m': six_month,
	'12m': year,
	'1g': day
};

export const ProductConf = {
	set: ({ loading, error, data }) => {
		return {
			loading,
			error,
			data: {
				product: _omit(data, ['product_special', 'product_skus']),
			}
		}
	},
};

export const PricesConf = {
	set: ({ loading, error, data }, product) => {
		if (product?.data && data) {
			return ({
				loading,
				error,
				data: {
					prices: product.data.product_skus.map((item) => {

						const slug = _last(_split(item.sku, '.'))

						return ({
							...item,
							price: _find(data, (price) => {
								switch (slug) {
									case '1m':
										return price.recurring.interval === 'month' && price.recurring.interval_count === 1
									case '6m':
										return price.recurring.interval === 'month' && price.recurring.interval_count === 6
									case '12m':
										return price.recurring.interval === 'year' && price.recurring.interval_count === 1
									case '1g':
										return price.unit_amount
									default:
										break;
								}
							}),
							..._setWith({}, 'template', slugToValueMap[slug], Object)
						})
					}),
					product_special: product.data.product_special,
				}
			})
		}

		return ({
			loading,
			error,
			data: {
				prices: [],
			}
		})
	},
};