import React, { Fragment, useCallback, useState } from "react";
import ConfirmDialog from "../common/confirm";
import Button from "../form/components/button";
import DeleteIcon from '@mui/icons-material/Delete';
import Loading from "../common/loading";

const DeleteComponent = ({
    loading = false,
    disabled = false,
    onDelete = () => { },
}) => {

    const [openDelete, setOpenDelete] = useState(false)

    const onOpen = useCallback(() => {
        setOpenDelete(true)
    }, [setOpenDelete]);

    const cancelAction = useCallback(() => {
        setOpenDelete(false)
    }, [setOpenDelete])


    const confirmAction = useCallback(() => {
        setOpenDelete(false)
        onDelete()
    }, [setOpenDelete, onDelete])


    return (
        <Fragment>
            {loading && <Loading />}
            <Button onClick={onOpen} variant="contained" startIcon={<DeleteIcon />} color="error" disabled={disabled}>
                Elimina
            </Button>
            <ConfirmDialog
                open={openDelete}
                onClose={cancelAction}
                title='Conferma'
                description="Sei sicuro di voler eliminare l'elemento selezionato?"
                cancelAction={cancelAction}
                confirmAction={confirmAction} />
        </Fragment>

    )
}

export default DeleteComponent