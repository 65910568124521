import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import menu from '../../module/menu';
import { module as auth } from '../../module/user/redux';
import AppBarComponent from "../../components/appbar";

const AppBarContainer = () => {

    const dispatch = useDispatch();
    const { open } = useSelector((state) => state.menu);

    const openDrawer = useCallback(() => {
        dispatch(menu.query.setOpenMenu.action(!open));
    }, [open, dispatch]);

    const onLogout = () => {
        dispatch(auth.slices.data.actions.logout())
    }

    return (
        <AppBarComponent 
            openDrawer={openDrawer}
            open={open}
            onLogout={onLogout}
        />
    )
}

export default AppBarContainer