import { fetchAll } from "../../../core/data/saga";
import { fetchDetail } from "../../../core/detail/saga";
import { createAction } from '@reduxjs/toolkit'

const tag = 'purchases'

const createSaga = (tag) => {    
    return [{
        action: createAction(`${tag}/all/data/fetch`),
        tag: `${tag}/all/data`,
        saga: fetchAll
    }, {
        action: createAction(`${tag}/detail/fetch`),
        tag: `${tag}/detail`,
        saga: fetchDetail
    } ]
}

export const module = {
    tag,    
    saga: createSaga(tag)
}