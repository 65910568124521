import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"

export const useSearch = (setValueAction, initialValue) => {

    const [text, setText] = useState(initialValue)
    const dispatch = useDispatch()
    const location= useLocation()
   
    const onChangeSearch = useCallback((value) => {
        dispatch(setValueAction(value))        
    }, [dispatch,location])


     const onKeyDown = useCallback((event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            onChangeSearch(text);
        }
    }, [text, onChangeSearch])


    const onChange = useCallback((event) => {
        event.preventDefault();
        const value = event.target.value
        setText(value)
        if (value === '') {
            onChangeSearch(value)
        }
    }, [setText, onChangeSearch]);

    const onClick = useCallback(() => {
        onChangeSearch(text);
    }, [text, onChangeSearch]);


    return {
        onKeyDown,
        onChange,
        onClick,
        text
    }
}