import { Stack, Divider, Chip, Grid, Box } from "@mui/material"
import dayjs from 'dayjs';
import { useMemo } from "react";
import { v4 as uuidv4 } from 'uuid';
import Text from "../../../../components/detail/text";
import Row from "../../../../components/detail/row";
import Copy from "../../../../components/actions/copy";
import { CouponPurchaseType } from "../../../../utility/Datasource.utility";
import _find from 'lodash/find'

const CouponCard = ({
    coupon
}) => {

    const info = useMemo(() => [
        ['Data Creazione', dayjs(coupon.created_at).format('DD/MM/YYYY')],        
        ['Validità in mesi', coupon.validity_in_months || ''],
        ['Prezzo di vendita', `${coupon.customer_purchase_amount || 0} €` || '-'],
        ['Tipologia', coupon.site_and_reader ? 'Sito e Giornale Digitale' : 'Sito' ],
        ['Tipo di Acquisto', _find(CouponPurchaseType, (i) => i.value === coupon.license_type)?.label ]
    ], [coupon])

    return (
        <Stack spacing={1}>
            <Stack direction={'row'} justifyContent={'space-between'} alignContent={'center'} paddingLeft={1} paddingRight={1}>
                <Box display={'flex'} flexDirection={'row'}>
                    <Text variant="subtitle1" enabled={true} title={`${coupon.code || ''}`} />
                    <Copy text={`${coupon.code || ''}`} />
                </Box>
                <Chip label={coupon.redeemed ? "Riscattato" : "Non Riscattato"} color={coupon.redeemed ? "success" : "primary"} variant={coupon.redeemed ? "filled" : "outlined"} />
            </Stack>
            <Divider />
            <Grid container spacing={1}>
                {info.map((item) =>
                    <Row key={uuidv4()} item={
                        <Text enabled={true} variant="body1" title={item[0]} value={item[1]} />
                    } />)}
            </Grid>
            <Divider />
        </Stack>
    )
}

export default CouponCard