export const API = {
    login: {
        path: '/auth/local',
        method: 'POST',
        get: null,
        auth: false,
        fallback: null,
    },
}

export const module = {
    ...API
}
