import { BarChart, Bar, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import { Box } from '@mui/material';
import Loading from '../../common/loading';

const Chart = ({
    data,
    loading,
}) => {

    return (
        <Box width={1}>
            {loading && <Loading type='progress' />}
            {data?.time &&
                <ResponsiveContainer height={450} >
                    <BarChart data={data.time.data}>
                        <XAxis dataKey={'name'} />
                        <YAxis />
                        {data.time.items.map(({ label, color, id }) =>
                            <Bar key={`bar-${id}`} dataKey={label} stackId="a" fill={color} />
                        )}
                        <Tooltip cursor={false} />
                    </BarChart>
                </ResponsiveContainer>
            }
        </Box>
    );
}

export default Chart