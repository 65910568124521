import * as yup from "yup";
import { API as newspapersAPI } from '../../../newspapers/api'
import { PurchasesStatus } from '../../../../utility/Datasource.utility'

export const FilterConf = {
    schema: yup.object({}),
    fields: [{
        id: "newspaper",
        name: "newspaper",
        type: "async_autocomplete",
        label: "Testata",
        api: newspapersAPI.select_with_sku,
        required: false,
        xs: 12,
        sm: 4
    }, {
        id: "status",
        name: "status",
        type: "autocomplete",
        label: "Stato Acquisto",
        options: PurchasesStatus,
        required: false,
        xs: 12,
        sm: 4
    },],
}

export const module = {
    ...FilterConf
}