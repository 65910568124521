import { Box, Drawer, Toolbar, List, ListItemIcon, ListItemText, ListItemButton, Stack, Divider } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import LogoSAE from '../../assets/sae';
import { drawerWidth, getIcon } from '../../module/menu/conf/Menu.conf';


const DrawerComponent = ({
    open = false,
    openDrawer = () => {},
    routes = []
}) => {

	const location = useLocation()
	const container = window !== undefined ? () => window.document.body : undefined;
	
	const drawer = (
		<Box pl={1} pr={1} alignItems='center' justifyContent='center'>
			<List>
				{routes.map((item) =>
					<Stack key={item.id}>
						<Link to={item.to} style={{ textDecoration: 'none' }}>
							<ListItemButton selected={location.pathname.includes(item.to)}>
								<ListItemIcon>
									{getIcon(item.icon)}
								</ListItemIcon>
								<ListItemText primary={item.title} />
							</ListItemButton>
						</Link>
						<Divider light />
					</Stack>
				)}
			</List>
		</Box>
	)

	return (
		<Box component='nav' sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }}}>
			<Drawer
				container={container}
				variant="temporary"
				open={open}
				onClose={() => openDrawer()}
				ModalProps={{ keepMounted: true }}
				sx={{
					display: { xs: 'block', sm: 'none' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
				}}
				color='inherit'>
				{drawer}
			</Drawer>
			<Drawer
				variant="permanent"
				sx={{
					display: { xs: 'none', sm: 'block' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: 'primary.main' },
				}}
				open>
				<Toolbar sx={{ display: 'flex' , alignItems: 'flex-end', justifyContent: 'center'}}>
					<Box width={80} height={80} padding={1}>
						<LogoSAE />
					</Box>					
				</Toolbar>	
				{drawer}
			</Drawer>
		</Box>
	);
};


export default DrawerComponent;
