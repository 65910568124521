import _get from 'lodash/get'
import { createSelector, createSlice } from '@reduxjs/toolkit';

// Slice
export const createSliceDetail = (tag) => createSlice({
    name: tag,
    initialState: {
        loading: false,
        error: null,
        data: null,
    },
    reducers: {
        fetch: (state) => state,
        success: (state, action) => {
            state.data = action.payload.data
            state.meta = action.payload.meta
            state.loading = false
            state.error = null
        },
        loading: (state) => {
            state.loading = true
            state.error = null
        },
        error: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.data = []
            state.meta = null
        },
        reset: (state) => {
            state.loading = false
            state.error = null
            state.data = null
            state.meta = null
        }
    }
})

// Selector
export const createSelectorDetail = (tag, transform = (data) => data) => createSelector(
    (state) => _get(state, `${tag}`),
    (data) => transform(data)
)