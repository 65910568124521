import { useNavigate } from 'react-router-dom';
import { useUpdate } from '../../hooks/update/useUpdate';
import { useEffect, isValidElement, Children, cloneElement } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useFetchDetail } from "../../hooks/detail/useFetchDetail";

const EditContainer = ({
    selector,
    slice,
    api,
    params,
    query,
    children
}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { actions } = slice
    const { fetchData } = useFetchDetail(actions.fetch, api)
    const { data: defaultValues, loading: loadingFetch, error } = useSelector(selector)
    const { loading, postData, data } = useUpdate(api.edit)

    const onSubmit = (values) => {
        postData({ ...params }, null, values)
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 10);
    }

    useEffect(() => {
        fetchData({ ...params }, { ...query })
        return () => {
            dispatch(actions.reset())
        }
    }, [actions, dispatch, fetchData, params, query])

    useEffect(() => {
        data && navigate(-1, { options: { replace: true } })
    }, [data, navigate])

    const childWithProps = Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child, { disabled: loading || loadingFetch, loading: loading || loadingFetch, defaultValues, error, onSubmit });
        }
        return child;
    });

    return childWithProps
};

export default EditContainer;
