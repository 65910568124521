import { useState } from "react";
import { useDispatch } from "react-redux";

export const useTableHead = (initialValue, action) => {

    const [value, setValue] = useState(initialValue);
    const dispatch = useDispatch();

    const onClick = (property) => {        
        const isAsc = value.orderBy === property && value.order === 'asc';        
        setValue({ orderBy: property, order: isAsc ? 'desc' : 'asc'})
    }

    const fetchSort = () => {
        dispatch(action({ order: value.order, orderBy: value.orderBy }));
    }

    return {        
        onClick,
        orderBy: value.orderBy,
        order: value.order,
        fetchSort
    };
}