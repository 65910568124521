import React, { useState } from 'react';
import Error from '../error';
import { useController, useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { grey } from '@mui/material/colors';
import { Box as MUIBox, Stack as MUIStack, Autocomplete as MUIAutocomplete, TextField as MUITextField } from '@mui/material';


const Autocomplete = ({ name, required, options, label, ...props }) => {
	const [open, setOpen] = useState(false);
	const {
		control,
		formState: { errors },
	} = useFormContext();

	const {
		field: { ref, onChange, value, ...inputProps },
	} = useController({
		name: name,
		control: control,
		rules: { required },
	});

	return (
		<MUIStack spacing={1}>
			<MUIAutocomplete
				{...inputProps}
				ref={ref}
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				options={options}
				value={value || null}
				getOptionLabel={(option) => option.label}
				isOptionEqualToValue={(option, value) => option.label === value.label}
				filterOptions={(options, state) => {
					// Filter out any options that have the same name as the user's input value
					const inputValue = state.inputValue.toLowerCase();
					return options.filter((option) =>
						_.includes(option.label.toLowerCase(), inputValue.toLowerCase())
					);
				}}
				renderOption={(_, option) => (
					<MUIBox
						key={uuidv4()}
						onClick={() => {
							setOpen(false)
							onChange(option);
						}}	
						padding={0.5}
						paddingLeft={1}					
						sx={{
							'&:hover': {
								backgroundColor: grey[200],
							},
						}}
					>
						{option?.label}
					</MUIBox>
				)}
				renderInput={(params) => (
					<MUITextField
						{...params}
						label={label}
						required={required}
						error={!!errors[name]?.message}
						size="small"
					/>
				)}
				onChange={(e, data) => onChange(data)}
				{...props}
			/>
			<Error error={errors[name]?.message} />
		</MUIStack>
	);
};

Autocomplete.propTypes = {
	name: PropTypes.string,
	required: PropTypes.bool,
	label: PropTypes.string,
	options: PropTypes.array.isRequired,
};

export default Autocomplete;
