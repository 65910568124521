import { Box, Paper } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { Fragment } from "react";
import SearchFilterContainer from "../../containers/searchfilter";
import AddButton from "../../components/actions/add";
import ListContainer from "../../containers/list";
import SearchContainer from "../../containers/search";
import FilterContainer from "../../containers/filter";
import { module as coupons } from '../../module/coupons/redux'
import { module as list } from '../../module/coupons/views/list'
import { module as filter } from '../../module/coupons/views/filter'
import { module as search } from '../../module/coupons/views/search'
import { module as api } from '../../module/coupons/api'
import Table from "../../components/table";
import SearchBar from "../../components/search";
import Filter from "../../components/filter";



const ListPage = () => {

    const navigate = useNavigate()
    const goToDetail = (id) => {
        navigate(`/coupons/${id}`)
    }

    return (
        <Fragment>
            <Box display={'flex'} justifyContent={'flex-end'}>
                <AddButton text={'Crea un nuovo coupon'} />
            </Box>
            <Paper sx={{ padding: 2, marginTop: 2 }}>
                <SearchFilterContainer
                    selector={coupons.selectors.filter}
                    search={
                        <SearchContainer selector={coupons.selectors.search} slice={coupons.slices.all.search}>
                            <SearchBar placeholder={search.placeholder} />
                        </SearchContainer>
                    }
                    filter={
                        <FilterContainer selector={coupons.selectors.filter} slice={coupons.slices.all.filter}>
                            <Filter conf={filter}/>
                        </FilterContainer>
                    } />
                <ListContainer selector={coupons.selectors.all} slice={coupons.slices.all} api={api}>
                    <Table onClick={(row) => goToDetail(row.id)} conf={list} />
                </ListContainer>
            </Paper>
        </Fragment>
    )
}

export default ListPage