import SubscriptionsIcon from '@mui/icons-material/ShoppingCart';
import ProfilesIcon from '@mui/icons-material/Group';
import PurchasesIcon from '@mui/icons-material/Newspaper';
import { Typography } from "@mui/material";

export const SubscriptionsCount = {
    conf: {
        label: 'Abbonamenti',
        icon: <SubscriptionsIcon />,
        link: '/subscriptions',
        color: 'secondary.main',
    },
    formatValue: (value) => <Typography variant='h4' sx={{ fontWeight: 'bold'}}>{value}</Typography>
};

export const ProfilesCount = {
    conf: {
        label: 'Utenti',
        icon: <ProfilesIcon />,
        link: '/profiles',
        color: 'primary.main'
    },
    formatValue: (value) => <Typography variant='h4' sx={{ fontWeight: 'bold'}}>{value}</Typography>
};

export const PurchasesCount = {
    conf: {
        label: 'Copie Singole',
        icon: <PurchasesIcon />,
        link: '/purchases',
        color: 'success.main',
    },
    formatValue: (value) => <Typography variant='h4' sx={{ fontWeight: 'bold'}}>{value}</Typography>
};


export const module = {
    purchases: PurchasesCount,
    profiles: ProfilesCount,
    subscriptions: SubscriptionsCount
}