export const API = {
	list: {
		path: '/newspapers',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},	
	count: {
		path: '/newspapers/count',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},
	detail: {
		path: '/newspapers/{{id}}',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},	
	select_with_sku: {
		path: '/newspapers',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
		transform: (data) => data.map((item) => ({
			name: item.title,
			id: item.sku_prefix
		}))
	},
	select_with_id: {
		path: '/newspapers',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
		transform: (data) => data.map((item) => ({
			name: item.title,
			id: item.id
		}))
	},
};