import { Paper, Stack, Typography } from '@mui/material';
import React from 'react';

const CustomTooltip = ({
    active,
    payload
}) => {

    if (active && payload && payload.length) {

        return (
            <Paper square elevation={1}>
                <Stack sx={{ backgroundColor: 'white', padding: 2 }}>
                    <Typography variant="subtitle2" color='text.secondary' fontWeight='bold'>
                        {payload[0].payload.name}
                    </Typography>
                    <Typography variant="subtitle1" color='text.primary' fontWeight='bold'>
                        Tot: {payload[0].payload.value}
                    </Typography>
                </Stack>
            </Paper>

        );
    }

    return null;
};

export default CustomTooltip