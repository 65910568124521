import Divider from '../components/divider';
import Autocomplete from '../components/sync';
import TextField from '../components/textField';
import AsyncAutocomplete from '../components/async';
import UploadButton from '../components/upload';
import DatePicker from '../components/date';
import RadioButtons from '../components/radio';
import Checkbox from '../components/checkbox';
import Typography from '../components/typography';
import CheckboxGroup from '../components/checkboxGroup';
import TimePicker from '../components/time';
import TextFieldPassword from '../components/password';

export const DynamicControl = ({
	id,
	name,
	type,
	label,
	options = [],
	url,
	api,
	required,
	sx,
	disableFuture,
	disablePast,
	...props
}) => {
	
	switch (type) {
		
		case 'text':
			return (
				<TextField {...props} name={name} required={required} id={id} label={label} />
			);
		case 'password':
			return (
				<TextFieldPassword {...props} name={name} required={required} id={id} label={label} />
			);
		case 'number':
			return (
				<TextField {...props} name={name} required={required} id={id} label={label} type='number'/>
			);
		case 'autocomplete':
			return (
				<Autocomplete {...props} name={name} required={required} id={id} label={label} options={options}/>
			);
		case 'async_autocomplete':
			return (
				<AsyncAutocomplete {...props} name={name} required={required} id={id} label={label} url={url} api={api}/>
			);
		case 'divider': {
			return <Divider {...props} label={label} />;
		}
		case 'upload': {
			return (
				<UploadButton {...props} name={name} required={required} id={id} label={label} />
			);
		}
		case 'date': {
			return (
				<DatePicker {...props} name={name} required={required} id={id} label={label} disableFuture={disableFuture} disablePast={disablePast}/>
			);
		}
		case 'radio': {
			return (
				<RadioButtons {...props} name={name} required={required} id={id} options={options} label={label}/>
			);
		}
		case 'checkbox_group': {
			return (
				<CheckboxGroup {...props} name={name} required={required} id={id} label={label} options={options}/>
			);
		}
		case 'checkbox': {
			return <Checkbox {...props} name={name} required={required} id={id} label={label} />;
		}
		case 'typography': {
			return <Typography {...props} {...sx}>{label}</Typography>;
		}
		case 'time': {
			return <TimePicker {...props} name={name} required={required} id={id} label={label}/>			
		}		
		default:
			return null;
	}
};