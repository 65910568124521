import { Divider } from "@mui/material"
import Alert from "../common/alert"
import Loading from "../common/loading"
import CardContainer from "./container"
import CTA from "./cta"
import Title from "./title"
import _isNull from 'lodash/isNull'
import _isUndefined from 'lodash/isUndefined'

const Card = ({
    conf,
    data,
    loading,
    error,
    render
}) => {

    return (
        <CardContainer>
            <Title backgroundColor={conf.color} label={conf.label} icon={conf.icon} />
            <Divider />
            {loading && <Loading type='progress' />}
			{error && <Alert severity='error'>{error}</Alert>}
            {_isNull(data) || _isUndefined(data) ? null : render(data)}
            <Divider />
            <CTA label={conf.label} link={conf.link} />
        </CardContainer>
    )
}

export default Card