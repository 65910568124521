import _get from 'lodash/get'
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { getItem, removeItem, saveItem } from "../../../utility/Storage.utility";

// Slice
export const createSliceUser = (tag) => createSlice({
    name: tag,
    initialState: {
        loading: false,
        error: null,
        jwt: getItem('jwt'),
        user: getItem('user')
    },
    reducers: {        
        fetch: (state) => state,
        loading: (state) => {
            state.loading = true
            state.error = null
            state.jwt = null
            state.user = null
            removeItem('jwt')
            removeItem('user')
        },
        success: (state, action) => {
            state.loading = false
            state.error = null
            state.jwt = action.payload.jwt
            state.user = action.payload.user
            saveItem('jwt', action.payload.jwt)
            saveItem('user', action.payload.user)
        },
        logout: (state) => {
            state.loading = false
            state.error = null
            state.jwt = null
            state.user = null
            removeItem('jwt')
            removeItem('user')
        },
        error: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.jwt = null
            state.user = null
            removeItem('jwt')
            removeItem('user')
        }
    }
})

// Selector
export const createSelectorUser = (tag, transform = (data) => data) => createSelector(
    (state) => _get(state, `${tag}.data`),
    (data) => transform(data)
);
