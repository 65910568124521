import { Avatar, Stack, Typography } from "@mui/material";

const Title = ({
    backgroundColor,
    label,
    icon,
}) => {

    return (
        <Stack direction='row' justifyContent={'space-between'} alignItems={'center'}>
            <Typography variant="subtitle1" color='text.secondary' fontWeight='bold'>
                {label.toUpperCase()}
            </Typography>
            <Avatar sx={{ backgroundColor, color: 'white' }}>
                {icon}
            </Avatar>
        </Stack>
    )
}

export default Title