import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { sagas } from '../sagas/sagas';
import { reducer as MenuReducer, tag as MenuTag } from '../module/menu';
import { createReducer } from '../core/utils/module/reducer';
import { module as UserModule } from '../module/user/redux'
import { module as CouponsModule } from '../module/coupons/redux'
import { module as SubscriptionsModule } from '../module/subscriptions/redux';
import { module as ProfilesModule } from '../module/profiles/redux';
import { module as PurchasesModule } from '../module/purchases/redux';
import { module as ProductsModule } from '../module/products/redux';
import { module as StatisticsModule } from '../module/stats/redux';
import { module as CanceledModule } from '../module/canceled/redux';

const appReducer = combineReducers({
	[UserModule.tag]: createReducer(UserModule.slices),
	[CouponsModule.tag]: createReducer(CouponsModule.slices),
	[ProfilesModule.tag]: createReducer(ProfilesModule.slices),
	[MenuTag]: MenuReducer,
	[SubscriptionsModule.tag]: createReducer(SubscriptionsModule.slices),
	[ProductsModule.tag]: createReducer(ProductsModule.slices),
	[PurchasesModule.tag]: createReducer(PurchasesModule.slices),
	[StatisticsModule.tag]: createReducer(StatisticsModule.slices),
	[CanceledModule.tag]: createReducer(CanceledModule.slices)
});


const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
	reducer: appReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});


sagaMiddleware.run(sagas);

export { store };
