import { Badge } from "@mui/material"
import FilterListIcon from '@mui/icons-material/FilterList';

const IconFilterComponent = ({    
    openFilter = () => {},
    badgeContent
}) => {

 
    return (
        <Badge badgeContent={badgeContent} color="error">
            <FilterListIcon onClick={openFilter} sx={{ cursor: 'pointer' }} />
        </Badge>
    )

}

export default IconFilterComponent