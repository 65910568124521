import Button from "../form/components/button";
import { Form } from "../form";
import ConfirmDialog from "../common/confirm";
import { useCallback, useEffect, useState } from "react";
import Section from "../detail/section";
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';

const SubscriptionComponent = ({
    disabled = false,
    fields,
    schema,
    profile,
    subscription,
    onSubmit,
}) => {

    const [state, setState] = useState({
        open: false,
        values: null
    })

    useEffect(() => {

        return (
            setState({
                open: false,
                values: null
            })
        )
    }, [])

    const cancelAction = useCallback(() => {
        setState({
            open: false,
            values: null
        })
    }, [setState])


    const confirmAction = useCallback(() => {
        onSubmit(state.values)
        setState({
            open: false,
            values: null
        })
    }, [onSubmit, state.values])

    const onConfirm = (values) => {
        setState({
            open: true,
            values: values
        })
    }

    return (
        profile && subscription &&
        <Section label={'Disdetta Abbonamento'} icon={<RemoveShoppingCartIcon />}>
            <Form fields={fields} schema={schema} onSubmit={onConfirm} defaultValues={{ profile_auth0: profile.auth0, subscription_id: subscription.id }}>
                <Button type="submit" variant='outlined' sx={{ mt: 1, mb: 2 }} disabled={disabled} color='error'>
                    Cancella l'abbonamento
                </Button>
            </Form>
            <ConfirmDialog
                open={state.open}
                onClose={cancelAction}
                title='Conferma'
                description="Sei sicuro di voler cancellare l'abbonamento selezionato?"
                cancelAction={cancelAction}
                confirmAction={confirmAction} />
        </Section>

    )
}

export default SubscriptionComponent