import _reduce from 'lodash/reduce'
import _groupBy from 'lodash/groupBy'
import _head from 'lodash/head'
import _split from 'lodash/split'
import _keys from 'lodash/keys'
import _find from 'lodash/find'
import _map from 'lodash/map'
import _filter from 'lodash/filter'
import _mapValues from 'lodash/mapValues'
import _orderBy from 'lodash/orderBy'
import _keyBy from 'lodash/keyBy'
import _get from 'lodash/get'
import dayjs from 'dayjs'
import { Newspapers, SubscriptionsPeriods, SubscriptionsTypes } from "../../../../utility/Datasource.utility";

function parseSKU(inputString) {

    const parsedData = {
        newspaper: _split(inputString, '-')[0],
        edition: _split(inputString, '-')[1].slice(0, 2),
        year: _split(inputString, '_')[1],
        month: _split(inputString, '_')[2].slice(0, 2),
        day: _split(inputString, '_')[2].slice(2),
    };

    return parsedData;
}


const setDataPieChart = (data, key) => {

    // Chart
    const map = _reduce(_groupBy(data, key), (result, value, key) => {
        const newKey = _head(_split(key, '.', 1));
        (result[newKey] || (result[newKey] = [])).push(...value);
        return result;
    }, {});

    const result = _keys(map).map((key) => {
        const newspaper = _find(Newspapers, (item) => item.id === key)
        return ({
            name: newspaper?.label,
            value: map[key]?.length,
            color: newspaper?.color
        })
    })

    return result
}

const setDataTimeChart = (data, groupedKey, findKey) => {

    // Chart
    const grouped = _groupBy(data, (item) => dayjs(item[groupedKey]).format('DD/MM/YYYY'))
    const result = _keys(grouped).map((key) => {
        const items = grouped[key]
        return ({
            name: key,
            ..._mapValues(_keyBy(Newspapers, 'label'), (newspaper) => _filter(items, (i) => _get(i, findKey).includes(newspaper.id)).length)
        })
    })

    return _orderBy(result, 'name')
}

const setDataChartSubscriptions = ({ loading, error, data }) => {

    // List
    const mapBySku = _map(data, (sub) => {
        const split = _split(sub.sku, '.')
        return ({
            newspaper: _find(Newspapers, (item) => item.id === split[0])?.id,
            type: _find(SubscriptionsTypes, (item) => item.id === split[1])?.id,
            period: _find(SubscriptionsPeriods, (item) => item.id === split[2])?.id
        })
    })

    const groupedByNewspaperAndType = _groupBy(mapBySku, (o) => `${o.newspaper}_${o.type}`);
    
    const list = _map(Newspapers, (newspaper) => ({
        slug: newspaper.id,
        name: newspaper.label,
        total: _filter(mapBySku, (o) => o.newspaper === newspaper.id).length,
        products: _map(SubscriptionsTypes, (type) => {
            const key = `${newspaper.id}_${type.id}`;
            const typeProducts = groupedByNewspaperAndType[key] || [];
            return {
                name: type.label,
                total: typeProducts.length,
                ..._mapValues(_groupBy(typeProducts, 'period'), (products) => products.length),
            };
        }),
        totals: _reduce(_map(SubscriptionsPeriods, (period) => ({ key: period.id,  value: _filter(mapBySku, (o) => o.period === period.id && o.newspaper === newspaper.id)?.length })), (result, value) => {
            result[value.key] = value.value;
            return result
        }, {})
    }));

    return { loading, error, data: { chart: setDataPieChart(data, 'sku'), list, time: { data: setDataTimeChart(data, 'created', 'sku'), items: Newspapers } } }
}

const setDataChartPurchases = ({ loading, error, data }) => {

    // List
    const map = _reduce(_groupBy(data, 'issue_sku.sku'), (result, value, key) => {
        (result[key] || (result[key] = [])).push(...value);
        return result;
    }, {});

    const list = _orderBy(_keys(map).map((key) => {
        const data = parseSKU(key);
        return ({
            sku: key,
            newspaper: _find(Newspapers, (o) => o.code === data.newspaper),
            edition: data.edition,
            count: map[key].length,
            date: _head(map[key])?.issue_sku.date
        })
    }), ['count', 'newspaper.label'], ['desc', 'desc'])


    return { loading, error, data: { chart: setDataPieChart(data, 'product_sku.sku'), list, time: { data: setDataTimeChart(data, 'created_at', 'product_sku.sku'), items: Newspapers } } }
}



export const ProfilesChartConf = {
    get: (data, query) => ({ data: setDataPieChart(data, 'product_sku.sku'), query })
}

export const SubscriptionsChartConf = {
    get: (data, query) => ({ data: setDataChartSubscriptions(data), query })
}

export const AllSubscriptionsChartConf = {
    get: (data, query) => ({ data: setDataChartSubscriptions(data), query })
}

export const PurchasesChartConf = {
    get: (data, query) => ({ data: setDataChartPurchases(data, 'product_sku.sku'), query })
}