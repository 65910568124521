import React from "react";
import { Box, Button } from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from "react-router-dom";


const CTA = ({
    link,
    label
}) => {

    return (
        <Box display={'flex'} justifyContent={'flex-end'}>
            <Link to={link}>
                <Button endIcon={<ArrowForwardIcon />}>
                    {label}
                </Button>
            </Link>
        </Box>
    )
}

export default CTA