export const Newspapers = [{
    id: 'iltirreno',
    label: 'Il Tirreno',
    color: '#2b4463',
    code: 'ILTIRRENO'
}, {
    id: 'lanuovasardegna',
    label: 'La Nuova Sardegna',
    color: '#f0cb78',
    code: 'LNNS'
}, {
    id: 'lanuovaferrara',
    label: 'La Nuova Ferrara',
    color: '#d32f2f',
    code: 'LNF'
}, {
    id: 'gazzettadimodena',
    label: 'Gazzetta di Modena',
    color: '#ed6c02',
    code: 'LGDM'
}, {
    id: 'gazzettadireggio',
    label: 'Gazzetta di Reggio',
    color: '#0288d1',
    code: 'LGDR'
}]

export const Stripe = [{
    id: 'iltirreno',
    label: 'Il Tirreno',    
    url: 'https://billing.stripe.com/p/login/cN2cOw5cneqh1Xi8ww'
}, {
    id: 'lanuovasardegna',
    label: 'La Nuova Sardegna',    
    url: 'https://billing.stripe.com/p/login/6oEg2A8o20iB8s8eUU'
}, {
    id: 'lanuovaferrara',
    label: 'La Nuova Ferrara',    
    url: 'https://billing.stripe.com/p/login/cN2cOw5cneqh1Xi8ww'
}, {
    id: 'gazzettadimodena',
    label: 'Gazzetta di Modena',    
    url: 'https://billing.stripe.com/p/login/cN2cOw5cneqh1Xi8ww'
}, {
    id: 'gazzettadireggio',
    label: 'Gazzetta di Reggio',    
    url: 'https://billing.stripe.com/p/login/cN2cOw5cneqh1Xi8ww'
}]

export const SubscriptionsTypes = [{
    id: 'full',
    label: 'Sito + Giornale',
    color: '#d32f2f'
}, {
    id: 'web',
    label: 'Sito',
    color: '#0288d1'
}]


export const SubscriptionsPeriods = [{
    id: '1w',
    label: 'Settimanale'
},{
    id: '1m',
    label: 'Mensile'
}, {
    id: '3m',
    label: 'Trimestrale'
}, {
    id: '6m',
    label: 'Semestrale'
}, {
    id: '12m',
    label: 'Annuale'
}]

export const CanceledStatus = [{
    id: 'canceled',
    label: `Cancellato`,
    hex: '#d32f2f',
    color: 'error'
}, {
    id: 'active',
    label: `Annullato`,
    hex: '#ed6c02',
    color: 'warning'
}]

export const SubscriptionsStatus = [{
    id: 'incomplete',
    label: `Ordine nel carrello`,
    hex: '#ed6c02',
    color: 'warning'
}, {
    id: 'incomplete_expired',
    label: `Carrello scaduto`,
    hex: '#d32f2f',
    color: 'error'
}, {
    id: 'active',
    label: 'Attivo',
    hex: '#2e7d32',
    color: 'success'
}, {
    id: 'canceled',
    label: 'Cancellato',
    hex: '#d32f2f',
    color: 'error'
}, {
    id: 'past_due',
    label: 'Insoluto',
    hex: '#d32f2f',
    color: 'error'
}]

export const SubscriptionsActive = [{
    id: 'active',
    label: "Attivi",
    value: true
}, {
    id: 'not_active',
    label: "Non Attivi",
    value: false
}, {
    id: 'all',
    label: "Tutti",
    value: null
}]


export const SubscriptionsPurchaseType = [{
    id: 'stripe',
    label: 'Stripe'
}, {
    id: 'imported_from_gedi',
    label: 'Importati da GEDI'
}, {
    id: 'redeem_code',
    label: 'Codice di riscatto'
}, {
    id: 'redeem_code_free',
    label: 'Codice di riscatto Free'
}, {
    id: 'redeem_code_paid',
    label: 'Codice di riscatto a Pagamento'
}, {
    id: 'free',
    label: 'Free'
},{
    id: 'apple',
    label: 'Apple'
}]


export const PurchasesStatus = [{
    id: 'requires_payment_method',
    label: 'Requires Payment Method',
    hex: '#d32f2f',
    color: 'error'
}, {
    id: 'requires_confirmation',
    label: 'Requires Confirmation',
    hex: '#d32f2f',
    color: 'error'
}, {
    id: 'requires_action',
    label: 'Requires Action',
    hex: '#d32f2f',
    color: 'error'
}, {
    id: 'processing',
    label: 'Processing',
    hex: '#d32f2f',
    color: 'error'
}, {
    id: 'requires_capture',
    label: 'Requires Capture',
    hex: '#d32f2f',
    color: 'error'
}, {
    id: 'canceled',
    label: 'Canceled',
    hex: '#d32f2f',
    color: 'error'
}, {
    id: 'succeeded',
    label: 'Succeeded',
    hex: '#2e7d32',
    color: 'success'
}, {
    id: 'partially_funded',
    label: 'Partially Funded',
    hex: '#d32f2f',
    color: 'error'
}, {
    id: 'refunded',
    label: 'Refunded',
    hex: '#d32f2f',
    color: 'error'
}] 

export const CouponsActive = [{
    id: 'active',
    label: "Riscattato",
    value: true
}, {
    id: 'not_active',
    label: "Non Riscattato",
    value: false
}, {
    id: 'all',
    label: "Tutti",
    value: null
}]

export const CouponSubscriptionType = [{
    id: 'full',
    label: "Sito e Giornale",
    value: true
},{
    id: 'web',
    label: "Solo Sito",
    value: false
}]

export const CouponPurchaseType = [{
    id: 'purchase',
    label: "Acquisto singolo",
    value: 'purchase'
},{
    id: 'free',
    label: "Omaggio",
    value: 'free'
},{
    id: 'multiple_copies',
    label: "Acquisto multiplo",
    value: 'multiple_copies'
}]

export const CouponsPrice = [{
    id: 'free',
    label: "Gratis",
    value: true
}, {
    id: 'pay',
    label: "A Pagamento",
    value: false
}, {
    id: 'all',
    label: "Tutti",
    value: null
}]


export const SubscriptionCancellation = [{
    id: 'renewal_cancellation',
    label: "Disdetta rinnovo automatico dell'abbonamento",
    value: 'renewal_cancellation'
}, {
    id: 'immediate_cancellation',
    label: "Disdetta immediata abbonamento SENZA rimborso",
    value: 'immediate_cancellation'
}, {
    id: 'immediate_cancellation_with_refund',
    label: "Disdetta immediata abbonamento CON rimborso",
    value: 'immediate_cancellation_with_refund'
}]