import { isValidElement, Children, cloneElement } from "react";
import { useSelector } from "react-redux";
import { useFilter } from "../../hooks/filter/useFilter";
import { useMemo } from "react";

const FilterContainer = ({
	selector,    
    slice,
	children	
}) => {	

	const { actions, getInitialState } = slice
	const { value } = useSelector(selector);
	const { onSubmit, onReset } = useFilter(actions.set, getInitialState())

	const defaultValues = useMemo(() => {
		return value || getInitialState().value
	}, [value])

	const childWithProps = Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child, { onSubmit, onReset , defaultValues, value });
        }
        return child;
    });

    return childWithProps
}

export default FilterContainer