import _get from 'lodash/get'

export const API = {
	list: {
		path: '/subscriptions',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},
	count: {
		path: '/subscriptions/count',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},
	detail: {
		path: '/subscriptions/{{id}}',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},
	cancel: {
		path: '/admin-cancel-subscription',
		method: 'PUT',
		get: null,
		auth: true,
		fallback: null,
		adapter: (body) => ({
			...body,
			cancel_type: _get(body, 'cancel_type.id') === 'renewal_cancellation'? 'at_period_end' : 'now',
			refund: _get(body, 'cancel_type.id')  === 'immediate_cancellation_with_refund'? 'true' : 'false',			
		})
	},
	download: {
		path: '/subscriptions-data-download-as-csv',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	}		
};

export const module = {
    ...API
}