import * as yup from 'yup';
import { Stripe } from '../../../../utility/Datasource.utility';

export const ChangeConf = {
    schema: yup.object({
        newspaper: yup.object().nullable().required('Campo obbligatorio').typeError('Campo obbligatorio'),
        email: yup.string().required('Campo obbligatorio').email('Inserire una email valida').nullable(),
    }),
    defaultValues: {
        newspaper: null,
        email: null,
    },
    fields: [{
        id: 'email',
        name: 'email',
        type: 'text',
        label: 'Email',
        required: true,
        disabled: true,
        xs: 6,
    }, {
        id: 'newspaper',
        name: 'newspaper',
        type: 'autocomplete',
        label: 'Testata',
        required: true,
        options: Stripe,
        xs: 6,
    }],
};

export const module = {
    ...ChangeConf
}