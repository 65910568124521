import { Container, Stack, Paper, Box } from '@mui/material';
import LogoSAE from '../assets/sae';
import LoginContainer from '../containers/login';

const LoginPage = () => {

    return (
        <Container disableGutters={true} maxWidth={false}>
            <Stack height={1} spacing={2} justifyContent='center' alignItems="center" bgcolor='primary.main'>                
                <Box width={120} height={120}>
                    <LogoSAE />
                </Box>
                <Paper sx={{ padding: 6 }}>
                    <LoginContainer />
                </Paper>
            </Stack>
        </Container>
    );
};

export default LoginPage;