import { createTheme } from "@mui/material";
const defaultTheme = createTheme();

export const theme = createTheme({
    palette: {
        mode: 'light',
        // primary: {
        //     main: '#2b4463',
        // },
        // secondary: {
        //     main: '#f0cb78',
        // },
        primary: {
            main: '#063974',
        },
        secondary: {
            main: '#921421',
        },
        border: {
            main: '#E0E0E0'
        },
        disabled: {
            main: '#697385'
        },
        background: {
            default: '#ebf0f4',
            paper: '#ffffff',
            main: '#ffffff'
        },
        card: {
            title: '#2b4463',
            value: '#697385',
            background: '#ebf0f4'
        }
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                head: {
                    backgroundColor: '#f9fafb'
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    ...(ownerState.selected ? { color: '#ffffff' } : { color: '#9da4a3' })
                }),
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    minWidth: '2.5rem'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontWeight: 'bold'
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    height: '100vh'
                }
            }
        }
    },
    typography: {
        // List View
        tableHead: {
            fontSize: defaultTheme.typography.body1.fontSize,
            fontWeight: 'bold'
        },
        tableRow: {
            fontSize: defaultTheme.typography.body2.fontSize,
            color: '#2A313F'
        },
        // Detail View
        sectionTitle: {
            fontSize: defaultTheme.typography.body1.fontSize,
            //color: '#FFFFFF', 
            fontWeight: 'bold',
        },
    }
});