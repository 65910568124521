import { useSelector } from "react-redux";
import { useEffect, isValidElement, Children, cloneElement } from "react";
import { useFetchList } from "../../hooks/table/useFetchList";
import _mapValues from 'lodash/mapValues'

const DataContainer = ({
    selector,
    slice,
    api,
    children
}) => {

    const { data: { data, loading, error }, query } = useSelector(selector)
    const actions = _mapValues(slice, (value) => value.actions ? _mapValues(value.actions, (action) => action) : {});
    const { fetchList } = useFetchList(actions.data.fetch, api)

    useEffect(() => {
        fetchList(query)
    }, [query])

    const childWithProps = Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child, { loading,  data, error });
        }
        return child;
    });

    return childWithProps
}

export default DataContainer