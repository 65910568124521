import { Stack , Typography } from '@mui/material';

const Text = ({
	variant = 'body1',
    title,
    value
}) => {

	
	return (
		<Stack spacing={0.5} margin={0.5}>
            {title &&
                <Typography variant={variant} fontWeight={'bold'} color={'card.title'}>
                    {title}
                </Typography>}
            {value &&
                <Typography variant={variant} color={'card.value'}>
                    {value}
                </Typography>}
        </Stack>
	);
};

export default Text;