import { Button, CircularProgress, Stack } from "@mui/material"
import DownloadIcon from '@mui/icons-material/Download';

const DownloadCSV = ({
    onDownload = () => { },
    loading = false
}) => {

    return (
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} alignItems={'center'}>
            <Button variant="outlined" startIcon={loading ? <CircularProgress variant="indeterminate" color="inherit" size={20} /> : <DownloadIcon />} onClick={onDownload} disabled={loading}>
                CSV
            </Button>
        </Stack>
    )
}

export default DownloadCSV