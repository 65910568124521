import { useState } from 'react';
import { fetchAPI } from '../../api/request';
import { useSelector } from "react-redux"
import { module as auth } from "../../module/user/redux";


export const useUpdate = (api) => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const { jwt } = useSelector(auth.selectors.user);

    const postData = (params, query, body) => {

        setLoading(true)
        fetchAPI(api, params, query, body, jwt)
            .then((response) => {
                setLoading(false)
                setError(null)
                setData(response)
            })
            .catch((error) => {
                setLoading(false)
                setError(error)
            })

    }

    return { loading, data, error, postData };
}
