import Text from '../../../../components/detail/text';
import ProfileCard from '../../../profiles/components/detail';
import PurchaseCard from '../../components/detail';
import ProfilesIcon from '@mui/icons-material/Group';
import NewspaperIcon from '@mui/icons-material/Newspaper';

export const DetailConf = {	
	fields: [
		{
			id: 'profile_section',
			type: 'section',
			label: 'Profilo',
			icon: <ProfilesIcon />,
			xs: 12,
			sm: 12,
			items: [{
				type: 'text',
				label: '',
				key: 'profile',
				xs: 12
			}],
		}, {
			id: 'shop_section',
			type: 'section',
			label: 'Copia Singola',
			icon: <NewspaperIcon />,
			xs: 12,
			sm: 12,
			items: [{
				type: 'text',
				label: '',
				key: 'purchase',
				xs: 12
			}],
		}],
	formatValue: (key, value, label) => {
		switch (key) {
			case 'profile':
				return <ProfileCard profile={value} />
			case 'purchase':
				return <PurchaseCard purchase={value} />
			default:
				return (
					<Text label={label} value={value} />
				);
		}
	},
};

export const module = {
	...DetailConf
}
