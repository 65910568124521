import { useDispatch, useSelector } from "react-redux"
import { module as auth } from "../../module/user/redux";

export const useFetchList = (action, api) => {

    const dispatch = useDispatch();
    const { jwt } = useSelector(auth.selectors.user);

    const fetchList = (query, params) => {        
        dispatch(action({ api: api.list, count: api.count, token: jwt, params, query }));
    }

    return {
        fetchList
    }

}