import { Button } from "@mui/material"
import { Link } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';

const UpdateButton = ({
    to
}) => {

    return (
        <Link to={to}>
            <Button
                variant='contained'
                color="primary"
                startIcon={<EditIcon />}>
                Modifica
            </Button>
        </Link>
    )
}

export default UpdateButton