import dayjs from 'dayjs';

export const ProfilesFilterConf = {
    initialState: {
        value: {
            start_date: dayjs().startOf('month').format('YYYY-MM-DD'),
            end_date: dayjs().endOf('day').add(1, 'days').format('YYYY-MM-DD')
        }
    },
    query: {
        keys: [{
            key: 'created_at',
            value: 'start_date',
            operator: 'gte'
        }, {
            key: 'created_at',
            value: 'end_date',
            operator: 'lte'
        },]
    }
}

export const SubscriptionsFilterConf = {
    initialState: {
        value: {
            is_active: true,
            start_date: dayjs().startOf('month').format('YYYY-MM-DD'),
            end_date: dayjs().endOf('day').add(1, 'days').format('YYYY-MM-DD')
        }
    },
    query: {
        keys: [{
            key: 'is_active',
            value: 'is_active',
            operator: 'eq'
        }, {
            key: 'created',
            value: 'start_date',
            operator: 'gte'
        }, {
            key: 'created',
            value: 'end_date',
            operator: 'lte'
        },]
    }
}

export const AllSubscriptionsFilterConf = {
    initialState: {
        value: {
            is_active: true,            
        }
    },
    query: {
        keys: [{
            key: 'is_active',
            value: 'is_active',
            operator: 'eq'
        }]
    }
}


export const PurchasesFilterConf = {
    initialState: {
        value: {
            status: 'succeeded',
            start_date: dayjs().startOf('month').format('YYYY-MM-DD'),
            end_date: dayjs().endOf('day').add(1, 'days').format('YYYY-MM-DD')
        }
    },
    query: {
        keys: [{
            key: 'status',
            value: 'status',
            operator: 'eq'
        }, {
            key: 'created_at',
            value: 'start_date',
            operator: 'gte'
        }, {
            key: 'created_at',
            value: 'end_date',
            operator: 'lte'
        }]
    }
}