import { useEffect, isValidElement, Children, cloneElement } from "react";
import { useDispatch, useSelector } from "react-redux"
import { useFetchDetail } from "../../hooks/detail/useFetchDetail";

const DetailContainer = ({
    selector,
    slice,
    api,
    params,
    query,
    children
}) => {

    const dispatch = useDispatch()
    const { actions } = slice
    const { fetchData } = useFetchDetail(actions.fetch, api)
    const { data, loading, error } = useSelector(selector)

    useEffect(() => {
        fetchData({ ...params }, { ...query })
        return () => {
            dispatch(actions.reset())
        }
    }, [])

    const childWithProps = Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child, { loading,  data, error });
        }
        return child;
    });

    return childWithProps
}

export default DetailContainer