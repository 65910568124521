import { Stack, Divider, Chip, Grid } from "@mui/material"
import dayjs from 'dayjs';
import { useMemo, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import Collapse from '@mui/material/Collapse';
import Text from "../../../../components/detail/text";
import Row from "../../../../components/detail/row";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const PurchaseCard = ({
    purchase
}) => {

    const enabled = useMemo(() => purchase.status === 'succeeded', [purchase.status])
    const [checked, setChecked] = useState(false);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const dates = useMemo(() => [
        ['Data di acquisto', dayjs(purchase.date).format('DD/MM/YYYY')],
    ], [purchase])

    const issue = useMemo(() => [
        ['Copia del', dayjs(purchase.issue_sku?.date).format('DD/MM/YYYY')],
        ['SKU', purchase.issue_sku?.sku],
    ], [purchase])


    const others = useMemo(() => [
        ['Codice Promozionale', purchase.purchase_redeem_code?.code],
        ['Riscattato', purchase.purchase_redeem_code?.redeemed === true ? 'SI' : ''],
    ], [purchase])


    return (
        <Stack divider={<Divider />}>

            <Stack direction={'row'} justifyContent={'space-between'} alignContent={'center'} padding={1} onClick={handleChange} sx={{ cursor: 'pointer' }}>
                <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
                    {checked ? <RemoveCircleOutlineIcon color="primary" /> : <AddCircleOutlineIcon color="primary" />}
                    <Text variant="subtitle1" enabled={true} title={`${purchase.product_sku?.name || ''} - (${dayjs(purchase.date).format('DD/MM/YYYY')})`} />
                </Stack>
                <Chip label={purchase.status} color={enabled ? "success" : "primary"} variant={enabled ? "filled" : "outlined"} />
            </Stack>

            <Collapse in={checked}>
                <Stack spacing={1} divider={<Divider />} padding={1}>
                    <Grid container >
                        {dates.map((item) =>
                            <Row key={uuidv4()} item={<Text enabled={enabled} variant="body1" title={item[0]} value={item[1]} />} />)}
                    </Grid>

                    <Grid container>
                        {issue.map((item) =>
                            <Row key={uuidv4()} item={
                                <Text enabled={enabled} variant="body1" title={item[0]} value={item[1]} />
                            } />)}
                    </Grid>

                    <Grid container>
                        {others.map((item) =>
                            <Row key={uuidv4()} item={
                                <Text enabled={enabled} variant="body1" title={item[0]} value={item[1]} />
                            } />)}
                    </Grid>                    
                </Stack>
                <Divider />
            </Collapse>
        </Stack>
    )
}

export default PurchaseCard