import TableCell, { CellType, CellVariant } from '../../../../components/table/cell';

export const ListConf = {	
	rowsPerPageOptions: [ 50, 100, 150, 200],
	columns: [		
		{
			id: 'email',
			key: 'email',
			sort_key: 'email',			
			label: 'Email',
		},
		{
			id: 'auth0',
			key: 'auth0',
			sort_key: 'auth0',			
			label: 'Auth0',
		},
		{
			id: 'created_at',
			key: 'created_at',
			sort_key: 'created_at',			
			label: 'Data di Registrazione',
			align: 'left'
		},		
		{
			id: 'deleted',
			key: 'deleted',
			sort_key: 'deleted',			
			label: 'Stato',
		},
	],
	render: {
		formatHead: (_, value) => {
			return (
				<TableCell variant={CellVariant.head} value={value} />
			);
		},
		formatValue: (key, value) => {
			switch (key) {
				case 'email':
					return (
						<TableCell type={CellType.email} value={value} />
					)
				case 'created_at':
					return (
						<TableCell type={CellType.date} value={value} />
					)
				case 'deleted':
					const status = {label: value === true? 'Disabilitato' : 'Attivo', color: value === true? 'error' : 'success'}
					return (
						<TableCell type={CellType.status} value={status} />
					)
				default:
					return (
						<TableCell type={CellType.default} value={value} />
					);
			}
		},
	},
	actions:{
		download: false
	}
};

export const module = {
    ...ListConf
}