export const API = {
	list: {
		path: '/products',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},
	skus: {
		path: '/subscription-skus',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},
	count: {
		path: '/products/count',
		method: 'GET',
		get: null,
		auth: true,
		fallback: null,
	},
	product: {
		detail: {
			path: '/products/{{id}}',
			method: 'GET',
			get: null,
			auth: true,
			fallback: null,
		}
	},
	prices: {
		detail: {
			path: '/prices?id_stripe={{id_stripe}}&newspaper={{slug}}',
			method: 'GET',
			get: null,
			auth: true,
			fallback: null,
		}
	}
};

export const module = {
	...API
}