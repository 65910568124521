import { useState } from 'react';
import { fetchAPI } from '../../api/request';
import { useSelector } from "react-redux"
import { module as auth } from "../../module/user/redux";

export const useDelete = (api) => {

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const { jwt } = useSelector(auth.selectors.user);

    const deleteData = (params, query) => {

        setLoading(true)
        fetchAPI(api.delete, params, query, null, jwt)
            .then(() => {
                setLoading(false)
                setError(null)
                setSuccess(true)
            })
            .catch((error) => {
                setLoading(false)
                setError(error)
                setSuccess(false)
            })
    }

    return {
        loading,
        success,
        error,
        deleteData
    };
}
