import { Paper } from "@mui/material"
import { useNavigate } from "react-router-dom";
import ListContainer from "../../containers/list";
import SearchContainer from "../../containers/search";
import { module as profiles } from '../../module/profiles/redux'
import { module as list } from '../../module/profiles/views/list'
import { module as search } from '../../module/profiles/views/search'
import { module as api } from '../../module/profiles/api'
import Table from "../../components/table";
import SearchBar from "../../components/search";

const ListPage = () => {

    const navigate = useNavigate()
    const goToDetail = (id) => {
        navigate(`/profiles/${id}`)
    }

    return (
        <Paper sx={{ padding: 2, marginTop: 2 }}>
            <SearchContainer selector={profiles.selectors.search} slice={profiles.slices.all.search}>
                <SearchBar placeholder={search.placeholder} />
            </SearchContainer>
            <ListContainer selector={profiles.selectors.all} slice={profiles.slices.all} api={api}>
                <Table onClick={(row) => goToDetail(row.auth0)} conf={list} />
            </ListContainer>
        </Paper>
    )
}

export default ListPage