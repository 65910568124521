import FilterComponent from "./form";
import ResetButton from "./reset";
import SubmitButton from "./submit";

const Filter = ({
    conf,
    value,
    defaultValues,
    onReset = () => { },
    onSubmit = () => { },
}) => {

    return (
        <FilterComponent
            conf={conf}
            reset={
                <ResetButton onReset={onReset} defaultValues={defaultValues} />
            }
            submit={
                <SubmitButton />
            }
            onSubmit={onSubmit}
            value={value} />
    )
}

export default Filter