import { CanceledStatus, SubscriptionsPurchaseType } from "../../../../utility/Datasource.utility"
import _find from 'lodash/find'

export const FilterConf = {
    initialState: {
        value: {
            newspaper: null,
            type_product: null,
            periodicity: null,
            status: _find(CanceledStatus, (status) => status.id === 'canceled' ),
            subscription_authorization_type: _find(SubscriptionsPurchaseType, (type) => type.id === 'stripe' ),
            start_date: null,
            end_date: null,
            cancel_at_period_end: null 
        }
    },
    query: {
        keys: [{
            key: 'product_sku.sku',
            value: 'newspaper.id',
            operator: 'contains'
        }, {
            key: 'status',
            value: 'status.id',
            operator: 'eq'
        }, {
            key: 'subscription_authorization_type',
            value: 'subscription_authorization_type.id',
            operator: 'eq'
        }, {
            key: 'product_sku.sku',
            value: 'type_product.id',
            operator: 'contains'
        }, {
            key: 'product_sku.sku',
            value: 'periodicity.id',
            operator: 'contains'
        }, {
            key: 'created',
            value: 'start_date',
            operator: 'gte'
        }, {
            key: 'created',
            value: 'end_date',
            operator: 'lte'
        },{
            key: 'cancel_at_period_end',
            value: 'cancel_at_period_end',
            operator: 'eq'
        },]
    }
}