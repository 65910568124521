import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _size from 'lodash/size'
import _pickBy from 'lodash/pickBy'
import IconFilterComponent from "../../components/filter/icon";
import SearchFilterComponent from "../../components/searchfilter";

const SearchFilterContainer = ({
    selector,
    search,
    filter,    
}) => {

    const { value } = useSelector(selector);
    const [checked, setChecked] = useState(true);

    const badgeContent = useMemo(() => _size(_pickBy(value, value => value !== null)), [value])

    const openFilter = () => {
        setChecked((prev) => !prev);
    };

    return (
        <SearchFilterComponent
            icon={
                <IconFilterComponent openFilter={openFilter} badgeContent={badgeContent}/>
            }
            search={search}
            filter={filter}
            checked={checked}
        />
    )
}

export default SearchFilterContainer