import { all } from "redux-saga/effects"
import { createSaga } from "../core/utils/module/saga"
import { module as UserModule } from '../module/user/saga'
import { module as CouponsModule } from '../module/coupons/saga'
import { module as SubscriptionsModule } from "../module/subscriptions/saga"
import { module as ProfilesModule } from "../module/profiles/saga"
import { module as PurchasesModule } from "../module/purchases/saga"
import { module as ProductsModule } from "../module/products/saga"
import { module as StatsModule } from "../module/stats/saga"
import { module as CanceledModule } from "../module/canceled/saga"


export function* sagas() {
	yield all([		
		createSaga(UserModule.saga),
		createSaga(CouponsModule.saga),
		createSaga(SubscriptionsModule.saga),
		createSaga(ProfilesModule.saga),
		createSaga(PurchasesModule.saga),
		createSaga(ProductsModule.saga),
		createSaga(StatsModule.saga),
		createSaga(CanceledModule.saga)										
	])
}


