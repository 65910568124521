import React from 'react';
import MuiTable from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';

const Table = ({
    children
}) => {

    return (
        <TableContainer sx={{ border: 1, borderColor: 'border.main', marginTop: 1}}>
            <MuiTable>
                {children}
            </MuiTable>
        </TableContainer>
    );
}

export default Table
