import { createSelector } from '@reduxjs/toolkit';
import { createSelectorData, createSliceData } from '../../../core/data/redux';
import { createSelectorDetail, createSliceDetail } from '../../../core/detail/redux';
import { createSelectorFilter, createSliceFilter } from '../../../core/filter/redux';
import { createSelectorQuery } from '../../../core/query';
import { ProfilesFilterConf, PurchasesFilterConf, SubscriptionsFilterConf, AllSubscriptionsFilterConf } from '../conf/filter';
import { ProfilesQueryConf, PurchasesQueryConf, SubscriptionsQueryConf, AllSubscriptionsQueryConf } from '../conf/query';
import { SubscriptionsChartConf, PurchasesChartConf,AllSubscriptionsChartConf } from '../conf/chart';

export const tag = 'stats';

export const createSelectors = (tag) => ({
    totals: {
        profiles: createSelectorDetail(`${tag}.totals.profiles`),
        subscriptions: createSelectorDetail(`${tag}.totals.subscriptions`),
        purchases: createSelectorDetail(`${tag}.totals.purchases`),
    },
    charts: {
        filter: {
            profiles: createSelectorFilter(`${tag}.charts.profiles`),
            subscriptions: createSelectorFilter(`${tag}.charts.subscriptions`),
            allsubscriptions: createSelectorFilter(`${tag}.charts.allsubscriptions`),
            purchases: createSelectorFilter(`${tag}.charts.purchases`)
        },
        profiles: createSelector([
            createSelectorData(`${tag}.charts.profiles`),
            createSelectorQuery(`${tag}.charts.profiles`, ProfilesQueryConf.get),
        ], (data, query) => ({ data, query })),
        subscriptions: createSelector([
            createSelectorData(`${tag}.charts.subscriptions`),
            createSelectorQuery(`${tag}.charts.subscriptions`, SubscriptionsQueryConf.get),
        ], SubscriptionsChartConf.get),
        allsubscriptions: createSelector([
            createSelectorData(`${tag}.charts.allsubscriptions`),
            createSelectorQuery(`${tag}.charts.allsubscriptions`, AllSubscriptionsQueryConf.get),
        ], AllSubscriptionsChartConf.get),
        purchases: createSelector([
            createSelectorData(`${tag}.charts.purchases`),
            createSelectorQuery(`${tag}.charts.purchases`, PurchasesQueryConf.get),
        ], PurchasesChartConf.get)
    }
})

const createSlices = (tag) => ({
    totals: {
        profiles: createSliceDetail(`${tag}/totals/profiles`),
        subscriptions: createSliceDetail(`${tag}/totals/subscriptions`),
        purchases: createSliceDetail(`${tag}/totals/purchases`)
    },
    charts: {
        profiles: {
            data: createSliceData(`${tag}/charts/profiles/data`),
            filter: createSliceFilter(`${tag}/charts/profiles/filter`, ProfilesFilterConf.initialState),
        },
        subscriptions: {
            data: createSliceData(`${tag}/charts/subscriptions/data`),
            filter: createSliceFilter(`${tag}/charts/subscriptions/filter`, SubscriptionsFilterConf.initialState),
        },
        allsubscriptions: {
            data: createSliceData(`${tag}/charts/all/subscriptions/data`),
            filter: createSliceFilter(`${tag}/charts/all/subscriptions/filter`, AllSubscriptionsFilterConf.initialState),
        },
        purchases: {
            data: createSliceData(`${tag}/charts/purchases/data`),
            filter: createSliceFilter(`${tag}/charts/purchases/filter`, PurchasesFilterConf.initialState),
        },
    }
})

export const module = {
    tag,
    selectors: createSelectors(tag),
    slices: createSlices(tag)
}