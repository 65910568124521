import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import { module as auth } from "../module/user/redux";


const AuthRoute = ({
    children
}) => {

    let location = useLocation();
    const { jwt , user } = useSelector(auth.selectors.user)

    useEffect(() => {
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 10);
    }, [location.pathname])

    if(jwt && user){
        return children
    }

    return <Navigate to="/" state={{ from: location }} replace />;
}

export default AuthRoute