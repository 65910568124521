import { Grid } from "@mui/material"

const Row = ({
    item,
}) => {

    return (
        <Grid item xs={12} sm={6}>
            {item}
        </Grid>
    )
}

export default Row