import _get from 'lodash/get'
import { createSlice, createSelector } from '@reduxjs/toolkit';

// Slice
export const createSliceSearch = (tag) => createSlice({
    name: tag,
    initialState: {
        value: ""
    },
    reducers: {
        set: (state, action) => {
            state.value = action.payload;
        }
    }
})

// Selector
export const createSelectorSearch = (tag, transform = (value) => value) => createSelector(
    (state) => _get(state, `${tag}.search`),
    (value) => transform(value)
)
