import { useLocation } from "react-router-dom"
import DrawerComponent from "../../components/drawer"
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import menu from '../../module/menu';
import { useRole } from "../../hooks/role/useRole";

const DrawerContainer = () => {

    const { drawer } = useRole()
    const location = useLocation()
    const dispatch = useDispatch();
    const { open, items } = useSelector((state) => state.menu);

    const openDrawer = useCallback(() => {
        dispatch(menu.query.setOpenMenu.action(!open));
    }, [open, dispatch]);

    useEffect(() => {
        dispatch(menu.query.setSelected.action(location.pathname));
    }, [location.pathname])

    useEffect(() => {
		dispatch(menu.query.setItems.action(drawer.routes))		
	}, [drawer])

    return (
        <DrawerComponent
            open={open}
            openDrawer={openDrawer}
            routes={items}
        />
    )
}

export default DrawerContainer