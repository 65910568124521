import React from "react";
import Button from "../form/components/button";
import { Form } from "../form";
import { Typography } from "@mui/material";
import Section from "../detail/section";
import NoEncryptionIcon from '@mui/icons-material/NoEncryption';

const Reset = ({
    disabled = false,
    fields,
    schema,
    onSubmit,
    user
}) => {

    return (
        user.profile &&
        <Section label={'Reset Password'} icon={<NoEncryptionIcon />}>
            <Form fields={fields} schema={schema} defaultValues={{ email: user.profile.email }} onSubmit={onSubmit}>
                <Typography variant="subtitle2" fontWeight={'bold'} color={'gray'}>
                    NB: Verrà inviata una mail all'indirizzo dell'utente con la procedura per il reset della password
                </Typography>
                <Button type="submit" variant='outlined' sx={{ mt: 1, mb: 2 }} disabled={disabled} color='error'>
                    Reset Password
                </Button>
            </Form>
        </Section>
    )
}

export default Reset