export const FilterConf = {    
    initialState: {
        value: {
            newspaper: null,
            redeemed: null,
        }
    },    
    query: {
        keys: [{
            key: 'newspaper.sku_prefix',
            value: 'newspaper.id',
            operator: 'contains'
        }, {
            key: 'redeemed',
            value: 'redeemed.value',
            operator: 'eq'
        }, {
            key: 'is_free',
            value: 'is_free.value',
            operator: 'eq'
        },{
            key: 'license_type',
            value: 'license_type.value',
            operator: 'eq'
        }]
    }
}