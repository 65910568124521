import { v4 as uuidv4 } from 'uuid';
import SubscriptionCard from "../../../subscriptions/components/detail";

const ProfileSubscriptions = ({
    subscriptions,
}) => {

    return (
        subscriptions.map((subscription) =>
            <SubscriptionCard key={uuidv4()} subscription={subscription} />
        )
    )
}

export default ProfileSubscriptions