import _isObject from 'lodash/isObject'
import { combineReducers } from '@reduxjs/toolkit';

function recursiveMapReducers(slicesObj) {
    const combinedReducers = {};

    Object.keys(slicesObj).forEach((key) => {
        const slice = slicesObj[key];
        if (typeof slice.reducer === 'function') {
            combinedReducers[key] = slice.reducer;
        } else if (_isObject(slice)) {
            const nestedReducers = recursiveMapReducers(slice, key);
            combinedReducers[key] = combineReducers(nestedReducers);
        }
    });

    return combinedReducers;
}

export const createReducer = (slices) => combineReducers(recursiveMapReducers(slices))
