import * as yup from "yup";

export const FilterConf = {
    schema: yup.object({}),    
    fields: [{
        id: "start_date",
        name: "start_date",
        type: "date",
        label: "Dal",        
        required: false,
        xs: 4,        
    },{
        id: "end_date",
        name: "end_date",
        type: "date",
        label: "Al",        
        required: false,
        xs: 4,        
    }]    
}

export const module = {
    ...FilterConf
}