import React from "react";
import Button from "../../form/components/button";
import { Form } from "../../form";
import { Stack, Typography } from "@mui/material";
import Section from "../../detail/section";
import PaymentIcon from '@mui/icons-material/Payment';

const Change = ({
    disabled = false,
    fields,
    schema,
    onSubmit,
    user
}) => {

    return (
        user.profile &&
        <Section label={'Modifica metodo di pagamento'} icon={<PaymentIcon />}>
            <Form fields={fields} schema={schema} defaultValues={{ email: user.profile.email }} onSubmit={onSubmit}>
                <Stack spacing={1}>
                    <Typography variant="subtitle2" fontWeight={'bold'} color={'gray'}>
                        NB: Verrai reindirizzato al portale esterno per avviare la procedura di modifica
                    </Typography>
                    <Button type="submit" variant='outlined' sx={{ mt: 1, mb: 2 }} disabled={disabled} color='error'>
                        Cambia metodo di pagamento
                    </Button>
                </Stack>
            </Form>
        </Section>
    )
}

export default Change