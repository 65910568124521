import _setWith from 'lodash/setWith'
import { SearchConf } from "../search"

export const QueryConf = {
    get: (list, search) => {

        const { pagination, sort } = list
        const { value: searchValue } = search

        return {
            _limit: pagination.rowsPerPage,
            _start: pagination.page * pagination.rowsPerPage,
            _sort: `${sort.orderBy}:${sort.order}`,
            _where: {
                _or: SearchConf.query.keys.map(({ key, operator }) => _setWith({}, `${key.split('.')}_${operator}`, searchValue, Object))
            }
        }
    }
}