import { Stack, Typography, Box, Divider } from '@mui/material';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const Legend = ({
    data,
}) => {

    return (
        <Stack direction={'row'} divider={<Divider orientation='vertical' flexItem/>}>
            {data?.chart && data.chart.map((item) =>
                <Stack key={uuidv4()} direction={'row'} justifyContent={'center'} alignItems={'center'} width={1} spacing={1}>
                    <Box width={8} height={8} bgcolor={item.color} borderRadius={4} />
                    <Typography variant="subtitle1" color='primary.main' textTransform={'uppercase'}>
                        {item.name}
                    </Typography>
                    <Typography variant="subtitle1" color='text.primary' fontWeight='bold'>
                        {item.value}
                    </Typography>
                </Stack>
            )}
        </Stack>
    )
}

export default Legend