
import Text from '../../../../components/detail/text';
import ProfileCard from '../../../profiles/components/detail';
import CouponIcon from '@mui/icons-material/ConfirmationNumber';
import SubscriptionsIcon from '@mui/icons-material/ShoppingCart';
import ProfilesIcon from '@mui/icons-material/Group';
import CustomerCard from '../../components/customer';
import CouponCard from '../../components/detail';
import SubscriptionCard from '../../../subscriptions/components/detail';


export const DetailConf = {	
	fields: [{
		id: 'coupon_section',
		type: 'section',
		label: 'Coupon',
		icon: <CouponIcon />,
		xs: 12,
		sm: 12,
		items: [{
			type: 'text',
			label: '',
			key: 'coupon',
			xs: 12
		}],
	}, {
		id: 'user_section',
		type: 'section',
		label: 'Cliente',
		icon: <ProfilesIcon />,
		xs: 12,
		sm: 12,
		items: [{
			type: 'text',
			label: '',
			key: 'customer',
			xs: 12
		}],
	}, {
		id: 'profile_section',
		type: 'section',
		label: 'Profilo',
		icon: <ProfilesIcon />,
		xs: 12,
		sm: 12,
		items: [{
			type: 'text',
			label: '',
			key: 'profile',
			xs: 12
		}],
	}, {
		id: 'subscription_section',
		type: 'section',
		label: 'Abbonamento',
		icon: <SubscriptionsIcon />,
		xs: 12,
		sm: 12,
		items: [{
			type: 'text',
			label: '',
			key: 'subscription',
			xs: 12
		}],
	}],
	formatValue: (key, value, label) => {
		switch (key) {
			case 'profile':
				return <ProfileCard profile={value} />
			case 'subscription':
				return <SubscriptionCard subscription={value} />
			case 'coupon':
				return <CouponCard coupon={value} />
			case 'customer':
				return <CustomerCard coupon={value} />
			default:
				return (
					<Text label={label} value={value} />
				);
		}
	},
};

export const module = {
    ...DetailConf
}

