import _setWith from 'lodash/setWith'
import _get from 'lodash/get'
import { SearchConf } from "../search"
import { FilterConf } from '../filter'

export const QueryConf = {
    get: (list, search, filter) => {

        const { pagination, sort } = list
        const { value: searchValue } = search
        const { value: filterValue } = filter

        if (searchValue && searchValue !== '') {
            return {
                _limit: pagination.rowsPerPage,
                _start: pagination.page * pagination.rowsPerPage,
                _sort: `${sort.orderBy}:${sort.order}`,
                _where: {
                    _or: SearchConf.query.keys.map(({ key, operator }) => _setWith({}, [`${key}_${operator}`], searchValue, Object)),
                    _and: FilterConf.query.keys.map(({ key, operator, value }) => _setWith({}, [`${key}_${operator}`], _get(filterValue, value), Object))
                }
            }
        }

        return {
            _limit: pagination.rowsPerPage,
            _start: pagination.page * pagination.rowsPerPage,
            _sort: `${sort.orderBy}:${sort.order}`,
            _where: {
                _and: FilterConf.query.keys.map(({ key, operator, value }) => _setWith({}, [`${key}_${operator}`], _get(filterValue, value), Object))
            }
        }
    }
}