import * as yup from 'yup';
import { API as newspapers } from '../../../newspapers/api'
import { CouponPurchaseType, CouponSubscriptionType } from '../../../../utility/Datasource.utility';
import dayjs from 'dayjs';

export const EditConf = {
    schema: yup.object({
        validity_in_months: yup.number().required('Campo obbligatorio').typeError('Campo obbligatorio'),
        newspaper: yup.object().nullable().required('Campo obbligatorio').typeError('Campo obbligatorio'),
        quantity: yup.number().required('Campo obbligatorio').typeError('Campo obbligatorio'),
        site_and_reader: yup.object().nullable().required('Campo obbligatorio').typeError('Campo obbligatorio'),
        customer_name: yup.string().nullable(),
        customer_email: yup.string().email('Inserire una email valida').nullable(),
        customer_purchase_date: yup.date().nullable(),
        invoice_number: yup.string().nullable(),
        determine_management: yup.string().nullable(),
        customer_purchase_amount: yup.number().nullable(),
        license_type:yup.object().nullable().required('Campo obbligatorio').typeError('Campo obbligatorio'),
    }),
    defaultValues: {
        validity_in_months: 12,
        newspaper: null,
        quantity: 1,
        site_and_reader: CouponSubscriptionType[0],
        customer_name: null,
        customer_email: null,
        customer_purchase_date: dayjs().format('DD/MM/YYYY'),
        invoice_number: null,
        determine_management: null,
        customer_purchase_amount: 0,
        license_type: CouponPurchaseType[0],
    },
    fields: [{
        id: 'divider_coupon',
        name: 'divider_coupon',
        type: 'divider',
        label: 'Coupon',
        xs: 12,
    }, {
        id: 'newspaper',
        name: 'newspaper',
        type: 'async_autocomplete',
        label: 'Testata',
        required: true,
        disabled: true,
        api: newspapers.select_with_id,
        xs: 6,
    }, {
        id: 'site_and_reader',
        name: 'site_and_reader',
        type: 'autocomplete',
        label: 'Tipo Abbonamento',
        required: true,
        disabled: true,
        options: CouponSubscriptionType,
        xs: 6,
    }, {
        id: 'license_type',
        name: 'license_type',
        type: 'autocomplete',
        label: 'Tipo di Acquisto',
        required: true,
        disabled: true,
        options: CouponPurchaseType,
        xs: 6,
    },{
        id: 'validity_in_months',
        name: 'validity_in_months',
        type: 'number',
        label: 'Validità (in mesi)',
        required: true,
        disabled: true,
        xs: 6,
    }, {
        id: 'quantity',
        name: 'quantity',
        type: 'number',
        label: 'Quantità',
        required: true,
        disabled: true,
        xs: 6,
    }, {
        id: 'customer_purchase_date',
        name: 'customer_purchase_date',
        type: 'date',
        label: 'Data della richiesta',
        required: false,
        disabled: true,
        xs: 6,
    }, {
        id: 'divider_customer',
        name: 'divider_customer',
        type: 'divider',
        label: 'Ente/Cliente',
        xs: 12,
    }, {
        id: 'customer_name',
        name: 'customer_name',
        type: 'text',
        label: 'Ente/Nome',
        required: false,
        xs: 6,
    }, {
        id: 'customer_email',
        name: 'customer_email',
        type: 'text',
        label: 'Email',
        required: false,
        xs: 6,
    }, {
        id: 'invoice_number',
        name: 'invoice_number',
        type: 'text',
        label: 'Numero Fattura',
        required: false,
        xs: 6,
    }, {
        id: 'determine_management',
        name: 'determine_management',
        type: 'text',
        label: 'Numero Determina',
        required: false,
        xs: 6,
    }, {
        id: 'customer_purchase_amount',
        name: 'customer_purchase_amount',
        type: 'number',
        label: 'Prezzo di vendita in €',
        required: false,
        xs: 6,      
    }],
};

export const module = {
    ...EditConf
}