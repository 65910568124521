import React, { Fragment, useState } from 'react';
import { Button, Snackbar, IconButton, Alert } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const CopyComponent = ({
    text = '',
    color = 'disabled',
    label
}) => {
    const [copied, setCopied] = useState(false);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(text);
        setCopied(true);
    };

    const handleCloseSnackbar = () => {
        setCopied(false);
    };

    return (
        <Fragment>

            <Button
                variant='text'
                color={color}
                startIcon={<FileCopyIcon />}
                onClick={handleCopyClick}>  
                {label}              
            </Button>

            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={copied}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                action={
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                        <FileCopyIcon fontSize="small" />
                    </IconButton>
                }>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Testo copiato negli appunti!
                </Alert>
            </Snackbar>
        </Fragment>
    );
};

export default CopyComponent;
