import React, { useState } from 'react';
import Error from '../error';
import { useController, useFormContext } from 'react-hook-form';
import { IconButton, InputAdornment, Stack as MUIStack, TextField as MUITextField } from '@mui/material';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const TextFieldPassword = ({ name, required, ...props }) => {

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const {
        control,
        formState: { errors },
    } = useFormContext();

    const {
        field: { ref, ...inputProps },
    } = useController({
        name: name,
        control: control,
        rules: { required },
    });

    return (
        <MUIStack spacing={0.5}>
            <MUIStack direction={'row'} spacing={2} alignItems={'center'}>
                {props.icon}
                <MUITextField
                    error={!!errors[name]?.message}
                    {...inputProps}
                    inputRef={ref}
                    {...props}
                    required={required}
                    fullWidth
                    size="small"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end">
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                    }}
                />
            </MUIStack>
            <Error error={errors[name]?.message} />
        </MUIStack>
    );
};

export default TextFieldPassword;
