export const FilterConf = {
    initialState: {
        value: {
            newspaper: null,
            redeemed: null,
        }
    },    
    query: {
        keys: [{
            key: 'newspaper.sku_prefix',
            value: 'newspaper.id',
            operator: 'contains'
        },]
    }
}