import { createSlice } from '@reduxjs/toolkit';

export const menuSlice = (name, initialState) =>
	createSlice({
		name,
		initialState: { ...initialState },
		reducers: {
			setSelected(state, action) {
				state.selected = action.payload;
			},
			setOpenMenu(state, action) {
				state.open = action.payload;
			},
			setItems(state, action) {
				state.items = action.payload
			}
		},
	});
