import * as yup from "yup";
import PersonIcon from '@mui/icons-material/PersonTwoTone';
import LockIcon from '@mui/icons-material/LockTwoTone';

export const LoginConf = {
	schema: yup.object({
		identifier: yup.string().nullable().required('Username is required'),
		password: yup.string().nullable().required('Password is required'),
	}),
	defaultValues: {
		identifier: '',
		password: ''
	},	
	fields: [{
		id: "identifier",
		name: "identifier",
		type: "text",
		label: "Username",
		required: true,
		xs: 12,
		icon: <PersonIcon sx={{ fontSize: 30}}/>
	}, {
		id: "password",
		name: "password",
		type: "password",
		label: "Password",
		required: true,
		xs: 12,
		icon: <LockIcon sx={{ fontSize: 30}}/>
	}]
}

export const module = {
    ...LoginConf
}