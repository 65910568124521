export const FilterConf = {
    initialState: {
        value: {
            newspaper: null,
        }
    },    
    query: {
        keys: [{
            key: 'product_sku.sku',
            value: 'newspaper.id',
            operator: 'contains'
        },{
            key: 'status',
            value: 'status.id',
            operator: 'eq'
        },]
    }
}