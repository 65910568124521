import { useDispatch, useSelector } from "react-redux"
import { module as auth } from "../../module/user/redux";

export const useFetchDetail = (action, api) => {    
        
    const dispatch = useDispatch();
    const { jwt } = useSelector(auth.selectors.user);

    const fetchData = (params, query) => {
        dispatch(action({ api: api.detail , token: jwt, params, query }));
    }

    return { 
        fetchData,
     }
}