import { Stack } from "@mui/material";
import React, { useMemo } from "react";
import { Form } from "../../form";


const FilterComponent = ({
    conf,
    reset,
    submit,
    onSubmit = () => { },
    value,
    children
}) => {

    const { schema, initialState, fields } = conf
    const defaultValues = useMemo(() => {
        return value || initialState.value
    }, [value, initialState])


    return (
        <Form fields={fields} schema={schema} defaultValues={defaultValues} onSubmit={onSubmit}>
            <Stack direction={'row'} spacing={1} width={1} justifyContent={'flex-end'} alignContent={'center'}>
                {reset}
                {submit}
            </Stack>
            {children}
        </Form>
    )
}

export default FilterComponent