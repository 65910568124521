import { useCallback, isValidElement, Children, cloneElement } from 'react';
import { useSelector } from 'react-redux';

const ChangeContainer = ({    
    selector,
    children
}) => {

    const { data: user } = useSelector(selector)

    const onChange = useCallback((values) => {        
        window.open(`${values.newspaper.url}?prefilled_email=${values.email}`)        
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 10);
    }, []);

    const childWithProps = Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child, { onSubmit: onChange, user });
        }
        return child;
    });

    return childWithProps
};

export default ChangeContainer;