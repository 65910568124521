import _ from 'lodash';
import { CanceledStatus } from '../../../../utility/Datasource.utility';
import TableCell, { CellType, CellVariant } from '../../../../components/table/cell';

export const ListConf = {
	rowsPerPageOptions: [50, 100, 150, 200],
	columns: [{
		id: 'product_sku.name',
		key: 'product_sku.name',
		sort_key: 'product_sku.name',
		label: 'Prodotto',
	}, {
		id: 'profile.email',
		key: 'profile.email',
		sort_key: 'profile.email',
		label: 'Email',
	}, {
		id: 'current_period_start',
		key: 'current_period_start',
		sort_key: 'current_period_start',
		label: 'Inizio',
	}, {
		id: 'current_period_end',
		key: 'current_period_end',
		sort_key: 'current_period_end',
		label: 'Fine',
	}, {
		id: 'canceled_at',
		key: 'canceled_at',
		sort_key: 'canceled_at',
		label: 'Cancellato',
	}, {
		id: 'status',
		key: 'status',
		sort_key: 'status',
		label: 'Stato'
	}],
	render: {
		formatHead: (_, value) => {
			return (
				<TableCell variant={CellVariant.head} value={value} />
			);
		},
		formatValue: (key, value) => {
			switch (key) {
				case 'profile.email':
					return (
						<TableCell type={CellType.email} value={value} />
					)
				case 'current_period_start':
				case 'current_period_end':
				case 'canceled_at':
					return (
						<TableCell type={CellType.date} value={value} />
					)
				case 'status':
					const status = _.find(CanceledStatus, (o) => o.id === value)
					return (
						<TableCell type={CellType.status} value={status} />
					)
				case 'product_sku.name':
					return (
						<TableCell type={CellType.sku} value={value} />
					)
				default:
					return (
						<TableCell type={CellType.default} value={value} />
					);
			}
		},
	},
	actions:{
		download: false
	}
};

export const module = {
	...ListConf
}