import { AppBar, Box, Stack, Toolbar, useTheme, IconButton } from '@mui/material';
import { drawerWidth } from '../../module/menu/conf/Menu.conf';
import MenuIcon from '@mui/icons-material/Menu';
import Logout from '@mui/icons-material/Logout';

const AppBarComponent = ({
    open = false,
    openDrawer= () => {},
    onLogout = () => {}
}) => {

    const theme = useTheme();

    return (
        <AppBar
            open={open}
            position='fixed'
            elevation={0}
            sx={{
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                ml: { sm: `${drawerWidth}px` },
                backgroundColor: theme.palette.background.paper
            }}>
            <Toolbar>
                <Stack direction={'row'} width={1}>
                    <IconButton onClick={openDrawer} sx={{ marginRight: 5, display: { sm: 'none' } }}>
                        <MenuIcon color='primary' sx={{ mr: 2 }} />
                    </IconButton>
                    <Box flexGrow={1} />
                    <IconButton onClick={onLogout} >
                        <Logout color='primary' />
                    </IconButton>
                </Stack>
            </Toolbar>
        </AppBar>
    );
};

export default AppBarComponent;
