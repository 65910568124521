import React from 'react';
import MUITextField from '@mui/material/TextField';
import Error from '../error';
import { useController, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PropTypes from 'prop-types';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

const TimePickerComponent = ({ name, required, label }) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const {
        field: { ref, onChange, ...inputProps },
    } = useController({
        name: name,
        control: control,
        rules: { required },
    });

    return (
        <Box display='flex' flexDirection={'column'}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                    ref={ref}
                    {...inputProps}
                    label={label}
                    onChange={(value) => onChange(value)}
                    renderInput={(params) => (
                        <MUITextField {...params} required={required} error={!!errors[name]?.message} />
                    )}
                    ampm={false}
                />
            </LocalizationProvider>
            <Error error={errors[name]?.message} />
        </Box>
    );
};

TimePickerComponent.propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool,
    label: PropTypes.string,
    disableFuture: PropTypes.bool,
    disablePast: PropTypes.bool,
};

export default TimePickerComponent;
