import React, { useMemo } from 'react';
import Button from '@mui/material/Button';
import { Box, Typography } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useFormContext } from 'react-hook-form';
import Error from '../error';

import PropTypes from 'prop-types';

const UploadButton = ({
    label,
    name,
    required,
}) => {

    const { formState: { errors }, register, watch } = useFormContext();

    const files = useMemo(() => {
        return watch(name) ? watch(name) : []
        // eslint-disable-next-line
    }, [watch(name)])

    return (
        <Box display='flex' flexDirection={'column'} sx={{ padding: 1 }}>
            <Button variant="outlined" startIcon={<FileUploadIcon />} component="label" color={!!errors[name]?.message ? 'error' : 'primary'}>
                {label}
                <input {...register(name)} hidden accept="file/*" multiple type="file" required={required} />
            </Button>
            <Typography variant='overline'>
                {`Choose a file ${required ? '*' : ''}`}
            </Typography>
            {Array.from(files).map((file) =>
                <Typography variant='caption' key={file.name}>
                    {file.name}
                </Typography>
            )}

            <Error error={errors[name]?.message} />
        </Box>
    );
}

UploadButton.propTypes = {
    name: PropTypes.string,
    required: PropTypes.bool,
    label: PropTypes.string,
};

export default UploadButton