import axios from "axios"
import { useDispatch } from "react-redux";
import { module as auth } from '../../module/user/redux'

const options = {
    baseURL: process.env.REACT_APP_BASE_URL,
}
const instance = axios.create(options);

export const Manager = instance

export const APIProvider = ({
    children
}) => {

    const dispatch = useDispatch()

    instance.interceptors.response.use(
        (response) => response,
        (error) => {               
            switch (error.response.status) {
                case 401:                    
                    return dispatch(auth.slices.data.actions.logout())                    
                default:
                    return Promise.reject(error);                    
            }
        }
    );


    return children
}
