import { Box } from "@mui/material"
import { useParams } from "react-router-dom";
import BreadcrumbsComponent from "../../components/breadcrumbs";
import DetailContainer from "../../containers/detail";
import { module as subscriptions } from '../../module/subscriptions/redux'
import { module as api } from '../../module/subscriptions/api'
import { module as detail } from '../../module/subscriptions/views/detail'
import { module as remove } from '../../module/subscriptions/views/delete'
import DetailComponent from "../../components/detail";
import { useSelector } from "react-redux";
import SubscriptionContainer from "../../containers/subscription";
import SubscriptionComponent from "../../components/subscription";

const links = [{
    to: '/subscriptions',
    label: 'Abbonamenti'
}]

const DetailPage = () => {

    const { id } = useParams();
    const { data } = useSelector(subscriptions.selectors.detail)

    return (
        <Box sx={{ width: '100%', height: '100%', padding: 1 }}>
            <BreadcrumbsComponent links={[...links, { to: `/subscriptions/${id}`, label: id }, { to: id, label: 'Dettaglio' }]} />
            <DetailContainer selector={subscriptions.selectors.detail} slice={subscriptions.slices.detail} api={api} params={{ id }}>
                <DetailComponent fields={detail.fields} render={detail.formatValue} />
            </DetailContainer>
            {data?.subscription?.is_active &&
                <SubscriptionContainer api={api.cancel} profile={data?.profile} subscription={data?.subscription}>
                    <SubscriptionComponent fields={remove.fields} schema={remove.schema} data={data} />                    
                </SubscriptionContainer>
            }
        </Box>
    )
}

export default DetailPage