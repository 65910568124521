import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AuthRoute from './AuthRoute';
import MainLayout from '../layout/main';
import LoginPage from '../pages/Login.page';

import ListProfilePage from '../pages/profile/List.page';
import DetailProfilePage from '../pages/profile/Detail.page';

import ListSubscriptionsPage from '../pages/subscriptions/List.page';
import DetailSubscriptionsPage from '../pages/subscriptions/Detail.page';

import ListCanceledPage from '../pages/canceled/List.page';
import DetailCanceledPage from '../pages/canceled/Detail.page';

import ListProductsPage from '../pages/products/List.page';
import DetailProductsPage from '../pages/products/Detail.page';

import ListPurchasesPage from '../pages/purchases/List.page';
import DetailPurchasesPage from '../pages/purchases/Detail.page';

import ListCouponsPage from '../pages/coupons/List.page';
import DetailCouponsPage from '../pages/coupons/Detail.page';
import AddCouponsPage from '../pages/coupons/Add.page';
import EditCouponsPage from '../pages/coupons/Edit.page';

import HomePage from '../pages/Home.page';


const Router = () => {
	return (
		<Routes>
			<Route path='/' element={<LoginPage />} />
			<Route element={<AuthRoute><MainLayout /></AuthRoute>}>

				{/* Home */}
				<Route path='/dashboard' element={<HomePage />} />

				{/* Profiles */}
				<Route path='/profiles' element={<ListProfilePage />} />
				<Route path='/profiles/:id' element={<DetailProfilePage />} />

				{/* Subscriptions */}
				<Route path='/subscriptions' element={<ListSubscriptionsPage />} />
				<Route path='/subscriptions/:id' element={<DetailSubscriptionsPage />} />

				{/* Canceled */}
				<Route path='/canceled' element={<ListCanceledPage />} />
				<Route path='/canceled/:id' element={<DetailCanceledPage />} />

				{/* Products */}
				<Route path='/products' element={<ListProductsPage />} />
				<Route path='/products/:id' element={<DetailProductsPage />} />

				{/* Purchases */}
				<Route path='/purchases' element={<ListPurchasesPage />} />
				<Route path='/purchases/:id' element={<DetailPurchasesPage />} />

				{/* Coupons */}
				<Route path='/coupons' element={<ListCouponsPage />} />
				<Route path='/coupons/:id' element={<DetailCouponsPage />} />
				<Route path='/coupons/edit/:id' element={<EditCouponsPage />} />
				<Route path='/coupons/add' element={<AddCouponsPage />} />				

			</Route>
		</Routes>
	);
};

export default Router;
