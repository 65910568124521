import { call, put } from 'redux-saga/effects'
import { fetchAPI } from "../../../api/request";

export function* fetchDetail(tag, action) {

    const { api, params, token, query } = action.payload

    try {
        yield put({ type: `${tag}/loading` })
        const data = yield call(fetchAPI, api, params, query, null, token);
        yield put({
            type: `${tag}/success`,
            payload: { data }
        })
    } catch (e) {
        console.log(e)
        yield put({
            type: `${tag}/error`,
            payload: "Errore di caricamento. Riprova più tardi o contatta l'assistenza"
        })
    }
}