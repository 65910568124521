import * as yup from "yup";
import { API as newspapersAPI } from '../../../newspapers/api'
import { CouponPurchaseType, CouponsActive, CouponsPrice } from '../../../../utility/Datasource.utility'

const FilterConf = {
    schema: yup.object({}),
    fields: [{
        id: "newspaper",
        name: "newspaper",
        type: "async_autocomplete",
        label: "Testata",
        api: newspapersAPI.select_with_sku,
        required: false,
        xs: 12,
        sm: 3
    }, {
        id: "redeemed",
        name: "redeemed",
        type: "autocomplete",
        label: "Stato Coupon",
        options: CouponsActive,
        required: false,
        xs: 12,
        sm: 3
    }, {
        id: "license_type",
        name: "license_type",
        type: "autocomplete",
        label: "Tipo di Acquisto",
        options: CouponPurchaseType,
        required: false,
        xs: 12,
        sm: 3
    }, {
        id: "is_free",
        name: "is_free",
        type: "autocomplete",
        label: "Prezzo",
        options: CouponsPrice,
        required: false,
        xs: 12,
        sm: 3
    }]
}

export const module = {
    ...FilterConf
}