import { useCallback, isValidElement, Children, cloneElement, useEffect } from 'react';
import { useUpdate } from '../../hooks/update/useUpdate';
import { useNavigate } from 'react-router-dom';

const SubscriptionContainer = ({
    api,
    profile,
    subscription,
    children
}) => {

    const { loading, data, postData, error } = useUpdate(api)
    const navigate = useNavigate()

    useEffect(() => {
        data && navigate(-1, { options: { replace: true } })
    }, [data, navigate])

    const onCancel = useCallback((values) => {
        postData(null, null, values)
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 10);
    }, [postData]);

    const childWithProps = Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child, { onSubmit: onCancel, profile, subscription, loading, error, data });
        }
        return child;
    });

    return childWithProps
};

export default SubscriptionContainer