import { CouponSubscriptionType } from '../../../../utility/Datasource.utility';
import _find from 'lodash/find'

export const EditConf = {
    set: ({ laoding, error, data }) => {
        return ({
            laoding,
            error,
            data: {
                validity_in_months: data?.validity_in_months,
                newspaper: { id: data?.newspaper.id, name: data?.newspaper.title },
                site_and_reader: _find(CouponSubscriptionType, (item) => item.value === data?.site_and_reader),
                quantity: 1,
                customer_email: data?.customer_email,
                customer_name: data?.customer_name,
                customer_purchase_date: data?.customer_purchase_date,
                invoice_number: data?.invoice_number,
                determine_management: data?.determine_management,
                customer_purchase_amount: data?.customer_purchase_amount
            }
        })
    },
};