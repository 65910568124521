import _setWith from 'lodash/setWith'
import _get from 'lodash/get'
import { ProfilesFilterConf, SubscriptionsFilterConf, PurchasesFilterConf, AllSubscriptionsFilterConf } from '../filter'

export const ProfilesQueryConf = {
    get: (list, search, filter) => {
        
        const { value: filterValue } = filter

        return {
            _limit: -1,            
            _where: {
                _and: ProfilesFilterConf.query.keys.map(({ key, operator, value }) =>_setWith({},[`${key}_${operator}`], _get(filterValue, value),Object)),
                _or: [{ 'deleted_null': true }, { 'deleted_eq': false }]
            }
        }
    }
}

export const SubscriptionsQueryConf = {
    get: (list, search, filter) => {
        
        const { value: filterValue } = filter

        return {
            _limit: -1,          
            _where: {
                _and: SubscriptionsFilterConf.query.keys.map(({ key, operator, value }) =>_setWith({},[`${key}_${operator}`], _get(filterValue, value),Object))
            }
        }
    }
}

export const AllSubscriptionsQueryConf = {
    get: (list, search, filter) => {
        
        const { value: filterValue } = filter

        return {
            _limit: -1,          
            _where: {
                _and: AllSubscriptionsFilterConf.query.keys.map(({ key, operator, value }) =>_setWith({},[`${key}_${operator}`], _get(filterValue, value),Object))
            }
        }
    }
}

export const PurchasesQueryConf = {
    get: (list, search, filter) => {
        
        const { value: filterValue } = filter

        return {
            _limit: -1,            
            _where: {
                _and: PurchasesFilterConf.query.keys.map(({ key, operator, value }) =>_setWith({},[`${key}_${operator}`], _get(filterValue, value),Object)),                
            }
        }
    }
}