import { useCallback, isValidElement, Children, cloneElement } from 'react';
import { useCreate } from '../../hooks/create/useCreate';

const CreateContainer = ({    
    api,
    children
}) => {

    const { loading, data, postData, error } = useCreate(api.create)

    const onCreate = useCallback((values) => {        
        postData(null, null, values)
        setTimeout(() => window.scrollTo({ top: 0, behavior: 'smooth' }), 10);
    }, [postData]);

    const childWithProps = Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child, { loading,  data, error, onSubmit: onCreate, disabled: loading });
        }
        return child;
    });

    return childWithProps
};

export default CreateContainer;