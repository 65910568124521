import _ from 'lodash'
import { Manager } from "../manager"

const interpolateRegex = /\{\{(\S+?)\}\}/g

export const fetchAPI = (settings, params = {}, query = '', body = {}, token = null) => {

    const {
        // basePath = '',
        path = '',
        method = 'GET',
        get, fallback, adapter, transform,
        //auth = false, 
        //...options 
    } = settings
    const paramsInterpolator = _.template(path, { interpolate: interpolateRegex })
    const interpolatedPath = paramsInterpolator(params)

    let headers = {}

    if (token) {
        headers['Authorization'] = `Bearer ${token}`
    }

    if (adapter) {
        body = adapter(body)
    }

    return (
        Manager({
            //baseURL: process.env.REACT_APP_BASE_URL,
            method,
            url: interpolatedPath,
            params: query,
            data: body,
            headers,            
        }).then(response => {
            if (typeof response.data === 'undefined') {
                return fallback
            }

            return [
                get && function (data) {
                    if (get) {
                        return _.get(data, get, fallback)
                    }
                    return data
                },
                transform instanceof Function && function (data) {
                    return transform(data, response, settings)
                }
            ]
                .filter(Boolean)
                .reduce((data, fn) => fn(data), response.data)
        })
    )
}