import { Box, CssBaseline, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';
import DrawerContainer from '../../containers/drawer';
import AppBarContainer from '../../containers/appbar';
import { drawerWidth } from '../../module/menu/conf/Menu.conf';


const MainLayout = () => {

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />

			{/* header */}
			<AppBarContainer />			

			{/* drawer */}
			<DrawerContainer />

			{/* main content */}
			<Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
				<Toolbar />
				<Outlet />
			</Box>
		</Box>
	);
};

export default MainLayout;
