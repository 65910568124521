import { useCallback } from "react"
import { useDispatch } from "react-redux"

export const useFilter = (setValuesAction, initialState) => {

    const dispatch = useDispatch()

    const handleChangeFilter = useCallback((values) => {
        dispatch(setValuesAction(values))
    }, [setValuesAction, dispatch])

    const onSubmit = useCallback((values) => {
        handleChangeFilter(values)
    }, [handleChangeFilter])

    const onReset = useCallback(() => {
        handleChangeFilter(initialState.value)
    }, [handleChangeFilter, initialState])

    return {
        onSubmit,
        onReset
    }
}