import * as React from 'react';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';

const SearchBar = ({
	placeholder = '',
	onChange = () => { },
	onClick = () => { },
	onKeyDown = () => { },
	value,
}) => {
	return (
		<Box width={1} display='flex'>
			<InputBase
				value={value}
				onChange={onChange}
				placeholder={placeholder}
				inputProps={{ 'aria-label': 'search' }}
				fullWidth={true}
				onKeyDown={onKeyDown}
				sx={{
					border: 1,
					borderRight: 0,
					paddingLeft: 1,
					borderColor: 'grey.300',
					borderTopLeftRadius: 4,
					borderBottomLeftRadius: 4
				}} />
			<Button variant="outlined"
				sx={{
					borderRadius: 0,
					borderTopRightRadius: 4,
					borderBottomRightRadius: 4
				}}
				onClick={onClick}>
				<SearchIcon />
			</Button>
		</Box>
	);
};

export default SearchBar;
