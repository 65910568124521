import { useSelector } from "react-redux"
import _first from 'lodash/first'
import { DrawerAdmin, DrawerDiffusione } from "../../module/menu/conf/Menu.conf"
import { module as auth } from '../../module/user/redux'

const Role = {
    ADMIN: 'crm_admin',
    DIFFUSIONE: 'crm_diffusione'
}

const drawer = (role) => {

    switch (role) {
        case Role.ADMIN:
            return DrawerAdmin
        case Role.DIFFUSIONE:
            return DrawerDiffusione
        default:
            return [];
    }
}


export const useRole = () => {

    const { selectors } = auth
    const { user } = useSelector(selectors.user);

    if (user) {
        return {
            drawer: drawer(user.role.type),
            root: _first(drawer(user.role.type).routes).to
        }
    }

    return ({
        drawer: {
            routes: []
        },
        root: '/'
    })
}