import Section from '../section';
import Text from '../text';

export const DynamicControl = ({ type, label, value }) => {
	switch (type) {				
		case 'text': {
			return <Text label={label} value={value} />;
		}
		case 'boolean':
			return <Text label={label} value={value ? 'true' : 'false'} />;

		default:
			return null;
	}
};