// REDUX
import { reducer, actions } from './slice';

// SELECTOR
import { tag } from './slice';


export { reducer, tag };

const menu = {
    tag,    
    query: {
        setSelected: {
            action: actions.setSelected,
        },
        setOpenMenu: {
            action: actions.setOpenMenu,
        },
        setItems: {
            action: actions.setItems,
        }
    },
};

export default menu;
