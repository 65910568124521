import ProfileCard from '../../components/detail';
import ProfilesIcon from '@mui/icons-material/Group';
import SubscriptionsIcon from '@mui/icons-material/ShoppingCart';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ProfileSubscriptions from '../../components/subscriptions';
import ProfilePurchases from '../../components/purchases';


export const ProfileConf = {	
	fields: [{
			id: 'profile_section',
			type: 'section',
			label: 'Profilo',
			icon: <ProfilesIcon />,
			xs: 12,
			sm: 12,
			items: [{
				type: 'text',
				label: '',
				key: 'profile',
				xs: 12
			}],
		}],
	formatValue: (key, value, label) => <ProfileCard profile={value} />
};


export const SubscriptionsConf = {	
	fields: [{
			id: 'shop_section',
			type: 'section',
			label: 'Abbonamenti',
			icon: <SubscriptionsIcon />,
			xs: 12,
			sm: 12,
			items: [{
				type: 'text',
				label: '',
				key: 'subscriptions',
				xs: 12
			}],
		}],
	formatValue: (key, value, label) => <ProfileSubscriptions subscriptions={value} />
};


export const PurchasesConf = {	
	fields: [{
			id: 'shop_section',
			type: 'section',
			label: 'Copie singole',
			icon: <NewspaperIcon />,
			xs: 12,
			sm: 12,
			items: [{
				type: 'text',
				label: '',
				key: 'purchases',
				xs: 12
			}],
		}],
	formatValue: (key, value, label) => <ProfilePurchases purchases={value} />	
};


export const module = {
    profile: ProfileConf,
	subscriptions: SubscriptionsConf,
	purchases: PurchasesConf
}