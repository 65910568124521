import { Stack, Grid, Divider } from "@mui/material"
import { useMemo } from "react"
import _template from 'lodash/template'
import _first from 'lodash/first'
import _last from 'lodash/last'
import Text from "../../../../components/detail/text";
import Row from "../../../../components/detail/row";
import { v4 as uuidv4 } from 'uuid';


const price = (sku) => {    
    if (sku.price?.tiers) {
        return [
            _template(sku.template.first)({ tier: _first(sku.price.tiers) }),
            _template(sku.template.last)({ tier: _last(sku.price.tiers) })
        ]
    }
    return [_template(sku.template.price)({ tier: sku.price })]
}

const ProductSku = ({
    sku
}) => {

    const info = useMemo(() => [
        ['Nome', sku.name],
        ['SKU', sku.sku],
        ['Prezzo', price(sku)],
    ], [sku])

    return (
        <Grid container>
            {info.map((item) =>
                <Row key={uuidv4()} item={
                    <Text enabled={true} variant="body1" title={item[0]} value={item[1]} />
                } />)}
        </Grid>
    )
}


const ProductSkus = ({
    skus
}) => {

    return (
        <Stack spacing={1} divider={<Divider />}>            
            {skus.map((sku) => <ProductSku key={sku.sku} sku={sku} />)}
        </Stack>
    )
}

export default ProductSkus