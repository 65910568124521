import { Box, Typography } from '@mui/material';
import CopyComponent from '../actions/copy';

const ClipboardComponent = ({    
    text = ''
}) => {
    
    return (
        <Box sx={{ borderRadius: 1, backgroundColor: 'background.main' }}>

            <Box display={'flex'} bgcolor={'background.default'} justifyContent={'flex-end'} sx={{ borderTopRightRadius: 4, borderTopLeftRadius: 4 }}>
                <CopyComponent text={text} color='primary' label='Copia'/>
            </Box>

            <Typography variant='body1' gutterBottom component={'pre'} sx={{ padding: 1 }}>
                {text}
            </Typography>            
        </Box>
    );
};

export default ClipboardComponent;
