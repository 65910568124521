import ProductCard from '../../components/detail';
import ProductSkus from '../../components/skus';
import ArticleIcon from '@mui/icons-material/Article';
import CouponIcon from '@mui/icons-material/ConfirmationNumber';

export const ProductConf = {
	initialState: {
		loading: false,
		error: null,
		data: null,
		meta: null,
	},
	fields: [{
		id: 'product_section',
		type: 'section',
		label: 'Prodotto',
		icon: <ArticleIcon />,
		xs: 12,
		sm: 12,
		items: [{
			type: 'text',
			label: '',
			key: 'product',
			xs: 12
		}],
	}],
	formatValue: (key, value, label) => <ProductCard product={value} />
};

export const PricesConf = {
	initialState: {
		loading: false,
		error: null,
		data: null,
		meta: null,
	},
	fields: [{
		id: 'skus_section',
		type: 'section',
		label: 'SKUs',
		icon: <CouponIcon />,
		xs: 12,
		sm: 12,
		items: [{
			type: 'text',
			label: '',
			key: 'prices',
			xs: 12
		}],
	}],
	formatValue: (key, value, label) => <ProductSkus skus={value} />
};

export const module = {
    product: ProductConf,
	prices: PricesConf
}

