import _head from 'lodash/head'
import _orderBy from 'lodash/orderBy'


export const ProfileConf = {
	set: ({ loading, error, data }) => {
		return {
			loading,
			error,
			data: {
				profile: _head(data)
			}
		}
	},
};


export const SubscriptionsConf = {
	set: ({ loading, error, data }) => {
		return {
			loading,
			error,
			data: {
				subscriptions: _orderBy(data, ['current_period_end', 'status'], ['desc', 'asc'])
			}
		}
	},
};


export const PurchasesConf = {
	set: ({ loading, error, data }) => {
		return {
			loading,
			error,
			data: {
				purchases: _orderBy(data, ['created_at'], ['desc', 'asc'])
			}
		}
	},
};
